import styled from 'styled-components'
import sizes from '../../styles/media'

export const RegularPrice = styled.p`
  color: #ff4d4d;
  text-decoration: line-through;
  margin: 0;
  line-height: 1.2;
  margin-bottom: 0.2rem;
`

export const Price = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  line-height: 1.2;
`

export const Title = styled.p`
  font-weight: bold;
  word-break: break-word;
  margin: 0;
`

export const Reference = styled.p`
  font-weight: bold;
  margin-bottom: 0.25rem;
  word-break: break-word;
  margin: 0;
`

export const Spacer = styled.div`
  width: 30px;
  height: 29px;
  display: block;
`

export const ContainerElements = styled.div`
  margin-bottom: 1rem;
`

interface Props {
  backgroundcolor?: string
  fontcolor?: string
  margin?: string
  fontSize?: string
}

export const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  @media ${sizes.sm} {
  justify-content: flex-start;
      }
`

export const DiscountLabel = styled.label`
  ${(props: Props) => {
    return `
      margin: ${props.margin ? props.margin : '0 10px 0 15px'};
      background-color: ${props.backgroundcolor};
      color: ${props.fontcolor}; 
      padding: 3px 10px;
      font-size:0.7rem;
      font-weight:bold;
      @media ${sizes.sm} {
        font-size:${props.fontSize ? props.fontSize : '1rem'};
        padding: 6px 12px;
      }
    `
  }};
`
