import React, { useState } from 'react'
import { ApolloProvider } from 'react-apollo'
import { createClient } from './client'
import gql from 'graphql-tag'
import { GlobalStyle } from './styles/reset.css'
import Variation from './model/Variation'
import Product from './model/Product'
import Routes from './routes'

import ThemeContext from './context/ThemeContext'
import CartContext from './context/CartContext'

import PaymentResponseContext from './context/PaymentResponseContext'
import LoaderContext from './context/LoaderContext'
import Notification from './components/Notification'

type Notifications = {
  type: string
  message: string
}

type Load = {
  loading: boolean
}

export type Response = {
  message: string
  title: string
  body: string[]
  image: string
  analyticId?: string
  requestId: string
  signature: string
  status: string
  orderId: string
  orderStatus: string
  reference: string
  emailCustomer: string
}

const GET_VARIATION_QUERY = gql`
  query getVariationQuery($id: ID!) {
    variation(id: $id) {
      id
      price
      regularPrice
      supplierDiscount
      supplierCost
      inventory {
        localStock
        storageStock
      }
    }
  }
`
const childRef = React.createRef<Notification>()
function App() {
  const CustomTheme = {
    primary: '#000000',
    secondary: '#FFFFFF',
    third: '#f7f7f7',
    btnPrimary: '#c59d5f',
    btnSecondary: '#FFFFFF'
  }

  const client = createClient()

  const expireLocalStorage = () => {
    const expiredTime = window.localStorage.getItem('expiredDate')
    const now = new Date().getTime()

    if (!expiredTime) {
      window.localStorage.removeItem('zones')
      window.localStorage.removeItem('cities')
      window.localStorage.setItem('expiredDate', now.toString())
    }
  }

  const initialItems = JSON.parse(window.localStorage.getItem('items') || '[]')
  const [items, setItems] = useState<Array<Variation>>(initialItems)
  const [loaded, setLoaded] = useState(false)
  const [deliverInfo, setDeliveryInfo] = useState('')
  const [loading, setLoadingState] = useState<Load>({ loading: false })
  const [response, setResponseState] = useState<Response>({
    message: '',
    title: '',
    body: [''],
    image: '',
    analyticId: '',
    requestId: '',
    signature: '',
    status: '',
    orderId: '',
    orderStatus: '',
    reference: '',
    emailCustomer: ''
  })

  const [notification, setNotification] = useState<Notifications>({
    type: '',
    message: ''
  })

  const [couponData, setCouponData] = useState<any>({})

  const onsetCouponData = (coupon: any) => {
    setCouponData(coupon)
  }

  const onSetNotification = (notification: Notifications) => {
    setNotification(notification)
  }

  const onSetLoaded = (loaded: boolean) => {
    setLoaded(loaded)
  }

  const setResponse = (response: Response) => {
    setResponseState(response)
  }

  const setLoading = (loading: Load) => {
    setLoadingState(loading)
  }

  const forceShowNotification = (notification: Notifications) => {
    childRef.current &&
      childRef.current.notify(notification.type, notification.message)
    return true
  }

  const initialItemSelected: Product = {
    name: '',
    slug: '',
    summary: '',
    description: '',
    supplier: {
      metaData: {
        horario: '',
        sectores: '',
        entrega: '',
        descuento: '',
        categoria: '',
        monto_minimo: 0,
        recomendado: ''
      },
      id: '',
      slug: '',
      business_name: '',
      legal_name: '',
      assets: [],
      city: ''
    },
    categories: [{ id: '', name: '', slug: '' }],
    assets: [{ url: '', order: 0 }],
    minPrice: 0,
    maxRegularPrice: 0,
    status: '',
    variations: [],
    features: []
  }
  const [itemSelected, setItemSelected] = useState<Product>(initialItemSelected)

  const validateStock = (variation: Variation, quantity: number) => {
    const totalStock =
      variation.inventory.localStock + variation.inventory.storageStock
    if (totalStock >= quantity) {
      return true
    }
    return false
  }

  const validateItem = (variation: Variation, quantityAdd: number) => {
    const totalItems = JSON.parse(window.localStorage.getItem('items') || '[]')
    let itemExist = false
    let currentItems = [...items]

    if (totalItems) {
      totalItems.map((item: Variation, i: number) => {
        const {
          id,
          quantity,
          inventory: { localStock, storageStock }
        } = item

        if (variation.id == id) {
          itemExist = true
          if (
            quantity + quantityAdd <= 10 &&
            localStock + storageStock >= quantity + quantityAdd
          ) {
            currentItems.splice(i, 1)
            variation.quantity = quantity + quantityAdd
            currentItems[i] = variation
          }
        } else {
          currentItems[i] = item
        }
      })
      if (itemExist) {
        setItemsLocalStorage(currentItems)
      }
    }
    return itemExist
  }

  const setItemsLocalStorage = (variation: Variation[]) => {
    setItems(variation)
    window.localStorage.removeItem('items')
    window.localStorage.setItem('items', JSON.stringify(variation))
  }

  const onLoadProductList = async (variation: Variation[]) => {
    setItemsLocalStorage(variation)
  }

  const validateSameRestaurantItems = (variation: Variation) => {
    const itemsLocal = JSON.parse(window.localStorage.getItem('items') || '[]')
    if (items.length === 0 || itemsLocal === null) {
      return true
    }
    const isValidVariation = items.find((item: Variation) => {
      if (item.product.supplier && variation.product.supplier) {
        return item.product.supplier.id === variation.product.supplier.id
      }
      return false
    })
    return !!isValidVariation
  }

  const changeDeliveryInfo = (info: string) => {
    setDeliveryInfo(info)
    window.localStorage.setItem('deliverInfo', info)
  }

  const onAddToCart = (variation: Variation, quantity: number) => {
    const haveStock = validateStock(variation, quantity)
    if (haveStock) {
      const newVariation: Variation = {
        id: variation.id,
        assets: variation.assets,
        inventory: {
          localStock: variation.inventory.localStock,
          storageStock: variation.inventory.storageStock
        },
        metaData: variation.metaData,
        options: variation.options,
        price: variation.price,
        quantity: variation.quantity,
        reference: variation.reference,
        regularPrice: variation.regularPrice,
        supplierCost: variation.supplierCost,
        supplierDiscount: variation.supplierDiscount,
        status: variation.status,
        product: {
          name: variation.product.name,
          slug: variation.product.slug,
          summary: variation.product.name,
          supplier: {
            id: variation.product.supplier ? variation.product.supplier.id : '',
            slug: variation.product.supplier
              ? variation.product.supplier.slug
              : '',
            business_name: variation.product.supplier
              ? variation.product.supplier.business_name
              : '',
            legal_name: variation.product.supplier
              ? variation.product.supplier.legal_name
              : '',
            assets: variation.product.supplier
              ? variation.product.supplier.assets
              : [],
            city: variation.product.supplier
              ? variation.product.supplier.city
              : '',
            metaData: variation.product.supplier
              ? variation.product.supplier.metaData
              : {
                  horario: '',
                  sectores: '',
                  entrega: '',
                  descuento: '',
                  categoria: '',
                  monto_minimo: 0,
                  recomendado: ''
                }
          },
          description: variation.product.name,
          categories: variation.product.categories,
          assets: variation.product.assets,
          minPrice: variation.product.minPrice,
          maxRegularPrice: variation.product.maxRegularPrice,
          status: variation.product.status,
          features: variation.product.features,
          variations: []
        }
      }
      const itemExist = validateItem(newVariation, quantity)

      if (!itemExist) {
        newVariation.quantity = quantity
        const newItems =
          window.localStorage.getItem('items') === null
            ? [newVariation]
            : [newVariation, ...items]

        window.localStorage.setItem('items', JSON.stringify(newItems))
        setItems(newItems)
      }
      return true
    }
    return false
  }

  const onGetProduct = (product: Product) => {
    setItemSelected(product)
  }

  const onRemoveFromCart = (index: number) => {
    const newItems = [...items]
    newItems.splice(index, 1)

    setItems(newItems)

    window.localStorage.setItem('items', JSON.stringify(newItems))
  }

  const onDelFromCart = (variation: Variation) => {
    validateItem(variation, -1)
  }

  const onDelAllItems = () => {
    window.localStorage.removeItem('items')
    // window.localStorage.removeItem('coupon')
    window.localStorage.removeItem('customer')
    setItems([])
  }

  const updateItems = async () => {
    if (items && client && client.query) {
      for (const variation of items) {
        const {
          id,
          price,
          regularPrice,
          inventory: { localStock, storageStock },
          quantity
        } = variation
        const { data, loading } = await client.query({
          query: GET_VARIATION_QUERY,
          variables: {
            id
          },
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })

        if (loading) {
          return false
        }

        if (!data) {
          return false
        }

        if (!data.variation) {
          variation.quantity = 0
        } else {
          if (
            data.variation.price != price ||
            data.variation.regularPrice != regularPrice ||
            data.variation.inventory.localStock != localStock ||
            data.variation.inventory.storageStock != storageStock
          ) {
            const totalStock =
              data.variation.inventory.localStock +
              data.variation.inventory.storageStock
            if (quantity > totalStock) {
              variation.quantity = totalStock
            }
            variation.price = data.variation.price
            variation.regularPrice = data.variation.regularPrice
            variation.inventory.localStock = data.variation.inventory.localStock
            variation.inventory.storageStock =
              data.variation.inventory.storageStock
          }
          variation.supplierDiscount = data.variation.supplierDiscount
          variation.supplierCost = data.variation.supplierCost
        }
      }
      onLoadProductList(items)
      return true
    }
    return false
  }

  expireLocalStorage()

  return (
    <ApolloProvider client={client}>
      <ThemeContext.Provider value={CustomTheme}>
        <CartContext.Provider
          value={{
            deliverInfo,
            items,
            itemSelected,
            onGetProduct,
            changeDeliveryInfo,
            onAddToCart,
            validateSameRestaurantItems,
            onRemoveFromCart,
            onDelFromCart,
            onDelAllItems,
            notification,
            onSetNotification,
            forceShowNotification,
            onLoadProductList,
            updateItems,
            onSetLoaded,
            loaded,
            couponData,
            onsetCouponData
          }}
        >
          <PaymentResponseContext.Provider value={{ response, setResponse }}>
            <LoaderContext.Provider value={{ loading, setLoading }}>
              <Notification ref={childRef}>
                {({ notify }) => {
                  notification &&
                    notification.type != '' &&
                    notify(notification.type, notification.message)
                  return <></>
                }}
              </Notification>
              <GlobalStyle />
              <Routes />
            </LoaderContext.Provider>
          </PaymentResponseContext.Provider>
        </CartContext.Provider>
      </ThemeContext.Provider>
    </ApolloProvider>
  )
}

export default App
