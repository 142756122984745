import React, { useContext, useState } from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import gql from 'graphql-tag'
import { NavIconButton } from '../../../Buttons'
import ThemeContext from '../../../../context/ThemeContext'
import PaymentResponseContext from '../../../../context/PaymentResponseContext'
import { Steps, TypeStep } from '../../../../helpers/steps'
import ResumeData from '../../../../helpers/resumeData'
import TextDelivery from '../../../../helpers/dateDelivery'
import CartContext from '../../../../context/CartContext'
import { StepperContainer, Step, Line, StepsContainer } from './style'

const GET_ORDER_STATUS = gql`
  query getOrderStatus($orderId: String!) {
    OrderStatus(orderId: $orderId) {
      status
    }
  }
`

function Stepper({ location, history, client }: any) {
  const { primary, btnPrimary } = useContext(ThemeContext)
  const { items } = useContext(CartContext)
  let { setResponse } = useContext(PaymentResponseContext)
  const [state, setState] = useState(true)
  if (items.length == 0) {
    location.pathname = '/'
  }

  const {
    orderStatus,
    orderId,
    requestId,
    signature,
    emailCustomer,
    reference
  } = JSON.parse(window.localStorage.getItem('response') || '{}')

  const actualStep = Steps.filter((step) => step.pathname === location.pathname)

  const getOrderStatus = async (order_id: string) => {
    const { data, loading } = await client.query({
      query: GET_ORDER_STATUS,
      variables: {
        orderId: order_id
      },
      fetchPolicy: 'network-only'
    })

    if (loading) {
      return ''
    }

    let res = data.OrderStatus.status
    if (res == 'paid') {
      const response = ResumeData(
        'APPROVED',
        requestId,
        signature,
        TextDelivery,
        emailCustomer,
        orderId,
        reference
      )
      setResponse && setResponse(response)
      window.localStorage.setItem('response', JSON.stringify(response))
    }
    if (res == 'canceled') {
      const response = ResumeData(
        'REJECTED',
        requestId,
        signature,
        TextDelivery,
        emailCustomer,
        orderId,
        reference
      )
      setResponse && setResponse(response)
      window.localStorage.setItem('response', JSON.stringify(response))
    }
    return res
  }

  const validatePendingOrders = async () => {
    if (orderStatus == 'processing' && state) {
      setState(false)
      await getOrderStatus(orderId)
      history.push('/resume')
    }
  }
  if (state) {
    validatePendingOrders()
  }

  if (actualStep.length > 0) {
    return (
      <StepperContainer>
        <StepsContainer>
          <Line />
          {Steps.map(({ pathname, name, icon, sizeicon, order }: TypeStep) => {
            if (orderStatus === 'paid') {
              return (
                <Step key={pathname}>
                  <NavIconButton color="#97d653" icon="ok" />
                  <span
                    style={{ color: '#75787b', marginTop: '0.5rem' }}
                    dangerouslySetInnerHTML={{
                      __html: name
                    }}
                  ></span>
                </Step>
              )
            }

            if (actualStep[0].order < order) {
              return (
                <Step key={pathname}>
                  <NavIconButton
                    color="#c5c5c5"
                    icon={icon}
                    sizeicon={sizeicon}
                  />
                  <span
                    style={{ color: '#c5c5c5', marginTop: '0.5rem' }}
                    dangerouslySetInnerHTML={{
                      __html: name
                    }}
                  ></span>
                </Step>
              )
            }

            if (actualStep[0].order === order) {
              return (
                <Step key={pathname}>
                  <NavIconButton
                    color={btnPrimary}
                    icon={icon}
                    sizeicon={sizeicon}
                  />
                  <span
                    style={{ color: primary, marginTop: '0.5rem' }}
                    dangerouslySetInnerHTML={{
                      __html: name
                    }}
                  ></span>
                </Step>
              )
            }

            return (
              <Step key={pathname}>
                <NavIconButton color="#97d653" icon="ok" />
                <span
                  style={{ color: '#75787b', marginTop: '0.5rem' }}
                  dangerouslySetInnerHTML={{
                    __html: name
                  }}
                ></span>
              </Step>
            )
          })}
        </StepsContainer>
      </StepperContainer>
    )
  }

  return (
    <StepperContainer>
      <StepsContainer>
        <Line />
        {Steps.map(({ pathname, name, icon, sizeicon, order }: TypeStep) => {
          return (
            <Step key={pathname}>
              <NavIconButton color="#97d653" icon="ok" />
              <span
                style={{ color: '#75787b', marginTop: '0.5rem' }}
                dangerouslySetInnerHTML={{
                  __html: name
                }}
              ></span>
            </Step>
          )
        })}
      </StepsContainer>
    </StepperContainer>
  )
}

export default withRouter(withApollo(Stepper))
