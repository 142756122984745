import React, { useRef } from "react";
import Layout from "../../components/Layout";
import { Column, Row } from "../../components/Grid";
import Catalog from "../../components/Catalog";
import Instructions from "../../components/Instructions";

import { MainBanner, Img } from "./style";
import { useIsMobile } from "../../helpers/resize";

import bannerDesktop from "../../assets/banners/banner-desktop.jpg";
import bannerMobile from "../../assets/banners/banner-mobile.jpg";
//import tomates from "../../assets/images/tomates.jpg";
import logo from "../../assets/images/logo-farms.png";

//import CartContext from "../../context/CartContext";

function Home() {
  //const { forceShowNotification } = useContext(CartContext);
  const CatalogRef = useRef<null | HTMLDivElement>(null);
  const scroll = () => {
    if (CatalogRef.current) {
      CatalogRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const initialMql = window.matchMedia(`(max-width: 768px)`);
  const isMobile = useIsMobile(initialMql.matches);
  const instructionsData = [
    {
      text: `<strong>Escoge la cantidad y <br />
      recurrencia de entrega</strong>`,
      imgIndex: 1,
    },
    {
      text: `<strong>Ingresa tus datos <br />
      de entrega</strong>`,
      imgIndex: 3,
    },
    {
      text: `<strong>Disfruta del sabor y dulzura <br /> de tus tomates en casa </strong>`,
      imgIndex: 5,
    },
  ];

  return (
    <Layout showBanner={true}>
      <MainBanner>
        {isMobile && <Img src={bannerMobile} />}
        {!isMobile && <Img src={bannerDesktop} />}
        <div className="banner">
          <img src={logo} />
          <h3>
            Lleva a tu casa deliciosos tomates, cultivados de la mejor manera,
            para ofrecer más sabor y dulzura.
          </h3>
        </div>
      </MainBanner>
      <Instructions instructions={instructionsData} scroll={scroll} />
      {/* <Row>
        <Column>
          <Title>Nuestro Producto</Title>
        </Column>
      </Row>
      <StyledRow>
        <Column md={6}>
          <h2>Tomates Hidropónicos cultivados orgánicamente.</h2>
          <p>
            El cultivo de tomate hidropónico permite supervisar la iluminación y
            temperatura que las plantas reciben, controlando así su sabor,
            dulzura y valor nutricional. <br />
            <br />
            Disfruta de estos deliciosos tomates y sigue cuidando a tu familia
            con los mejores productos.
          </p>
        </Column>
        <Column md={6}>
          <img src={tomates} />
        </Column>
      </StyledRow> */}
      <Row>
        <div style={{ width: "100%" }} ref={CatalogRef}>
          <Column md={12}>
            <Catalog />
          </Column>
        </div>
      </Row>
    </Layout>
  );
}

export default Home;
