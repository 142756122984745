import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../../../../context/ThemeContext";
import CartContext from "../../../../context/CartContext";

import {
  FlexRow,
  SummaryContainer,
  ProductContainer,
  ImageContainer,
  Img,
  DescriptionContainer,
  ProductName,
  Reference,
  DetailPayment,
  SpanPayment,
  TotalContainer,
} from "./style";
import formatNumber from "../../../../helpers/formatNumber";
import noImage from "../../../../assets/images/product-default.svg";

function ProductSummary() {
  const { primary, secondary } = useContext(ThemeContext);

  const {
    items,
    loaded,
    onSetLoaded,
    updateItems,
    forceShowNotification,
    onRemoveFromCart,
  } = useContext(CartContext);

  useEffect(() => {
    calculatePayment();
    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok);
      });
  });

  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [iva, setIva] = useState(0);

  const calculatePayment = () => {
    let currentTotal = 0;
    let currentSubtotal = 0;
    items.forEach((item) => {
      currentTotal += item.price * item.quantity;
    });
    currentSubtotal = currentTotal / 1.12;
    setTotal(currentTotal);
    setSubtotal(currentSubtotal);
    setIva(currentTotal - currentSubtotal);
  };
  return loaded ? (
    <>
      {loaded ? (
        <>
          <SummaryContainer backgroundcolor={secondary}>
            {items.map((item, index: number) => {
              if (item.quantity > 0) {
                return (
                  <ProductContainer key={item.id}>
                    <ImageContainer>
                      <Img src={noImage} />
                    </ImageContainer>
                    <DescriptionContainer>
                      <ProductName>
                        <span>{item.product ? item.product.name : ""}</span>
                      </ProductName>
                      {item.reference.indexOf(item.product.name) === -1 && (
                        <Reference>{item.reference}</Reference>
                      )}
                      <span className="quantity">
                        {`Cantidad ${item.quantity}`}
                      </span>
                      <span>
                        <b>$ {formatNumber(item.price)}</b>
                      </span>
                    </DescriptionContainer>
                  </ProductContainer>
                );
              } else {
                if (forceShowNotification && onRemoveFromCart) {
                  let result = forceShowNotification({
                    type: "fail",
                    message: item.product.name + " se ha quedado sin stock",
                  });
                  if (result) onRemoveFromCart(index);
                }
                return <div key={index} />;
              }
            })}
          </SummaryContainer>
          <DetailPayment backgroundcolor={secondary}>
            <FlexRow>
              <span>SUBTOTAL:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(subtotal)}
              </SpanPayment>
            </FlexRow>
            <FlexRow>
              <span>ENVÍO:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(0)}
              </SpanPayment>
            </FlexRow>
            <FlexRow>
              <span>IVA:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(iva)}
              </SpanPayment>
            </FlexRow>
          </DetailPayment>
          <TotalContainer backgroundcolor={"#ffffff"}>
            <SpanPayment fontcolor={primary}>TOTAL</SpanPayment>
            <SpanPayment fontcolor={primary}>
              $ {formatNumber(total)}
            </SpanPayment>
          </TotalContainer>
        </>
      ) : null}
    </>
  ) : null;
}

export default ProductSummary;
