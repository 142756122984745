import React from "react";
import { DateDeliveyContainer, DateInfo, DateText, DateIcon } from "./style";
//import CartContext from '../../../../context/CartContext'
import timer from "../../../../assets/images/clock.svg";

function DateDelivery() {
  //const { deliverInfo } = useContext(CartContext)
  //const deliverInfoLS = window.localStorage.getItem('deliverInfo')

  const message = `<p>Recuerda que las entregas se realizarán los días viernes de cada semana.</p><br/><p>El valor de la suscripción se verá reflejado en tu tarjeta de crédito Grande Table.</p>`;
  return (
    <DateDeliveyContainer>
      <DateIcon>
        <img src={timer} />
      </DateIcon>
      <DateInfo>
        <DateText dangerouslySetInnerHTML={{ __html: message }} />
      </DateInfo>
    </DateDeliveyContainer>
  );
}

export default DateDelivery;
