import React, { useContext, useState } from 'react'
import { Sticky } from 'react-sticky'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import {
  HeaderWrapper,
  FooterWrapper,
  Main,
  StyledSideBar,
  StyledContainer,
  CustomStickyContainer
} from './style'
import FullLoader from '../../components/FullLoader'
import ThemeContext from '../../context/ThemeContext'
import LoaderContext from '../../context/LoaderContext'
import MenuItems from '../MenuItems'

type Props = {
  children: React.ReactNode
  backgroundColor?: string
  isFixed?: boolean
  showBanner?: boolean
}

export type CouponNotification = {
  hasCoupon: boolean
  couponValue: string
  showNotification: ({}: { type: string; message: string }) => void
}

function Layout({ children, backgroundColor, isFixed, showBanner }: Props) {
  const { primary, third } = useContext(ThemeContext)

  let {
    loading: { loading }
  } = useContext(LoaderContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarTop, setSidebarTop] = useState('5rem')

  const onSetSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen)
  }

  var token = window.localStorage.getItem('token') || ''
  if (!token) {
    token =
      Math.random().toString(36).substring(2, 15).toUpperCase() +
      Math.round(+new Date() / 1000)
    window.localStorage.setItem('token', token)
  }

  return (
    <CustomStickyContainer>
      <StyledSideBar
        sidebar={<MenuItems />}
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{
          sidebar: {
            position: 'fixed',
            background: 'white',
            top: sidebarTop,
            zIndex: '3'
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap'
          }
        }}
      >
        {loading ? <FullLoader /> : <></>}
        <Sticky topOffset={45}>
          {({ style, isSticky }) => {
            setSidebarTop(isSticky === true ? '4.5rem' : '4rem')
            return (
              <HeaderWrapper
                style={style}
                backgroundcolor={primary}
                showBanner={showBanner}
              >
                <Header
                  sidebarOpen={sidebarOpen}
                  onSetSidebarOpen={onSetSidebarOpen}
                />
              </HeaderWrapper>
            )
          }}
        </Sticky>

        <Main backgroundcolor={backgroundColor ? backgroundColor : third}>
          <StyledContainer>{children}</StyledContainer>
        </Main>
        <FooterWrapper backgroundcolor={primary}>
          <Footer />
        </FooterWrapper>
      </StyledSideBar>
    </CustomStickyContainer>
  )
}

export default Layout
