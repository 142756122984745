export type TypeItems = {
  pathname: string;
  name: string;
  order: number;
  sub_item?: [TypeItems];
};

export const Items: TypeItems[] = [
  {
    pathname: "/contact",
    name: "Contáctanos",
    order: 3
  },
  {
    pathname: "/faqs",
    name: "Preguntas frecuentes",
    order: 4
  }
];
