import styled from "styled-components";
import { Link } from "react-router-dom";
// import sizes from "../../styles/media";

interface Props {
  fontcolor?: string;
}

export const LinkResponsive = styled(Link)`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor};
      font-size: 12px;
      text-decoration: underline;
      font-weight: bold;
    `;
  }}

  color: #c59d5f;
  &:hover {
    color: #c59d5f;
  }
  &:visited {
    color: #c59d5f;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const H3 = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: black;
`;
