import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Variation from "../../model/Variation";
import { ApolloConsumer } from "react-apollo";
import * as Grid from "../Grid";
import Icon from "../Icon";
import {
  Img,
  ActionLinksContainer,
  BurguerContainer,
  LogoContainer,
  StyledLogoLinkContainer,
  HeaderLinks,
  CustomColumn,
} from "./style";
import { useIsMobile } from "../../helpers/resize";
import logoDesktop from "../../assets/header/logo-gt.svg";
import logoMobile from "../../assets/header/logo-gt.svg";

import CartContext from "../../context/CartContext";

type Props = {
  sidebarOpen: boolean;
  onSetSidebarOpen: () => void;
  updateItems?: () => void;
};

function Header({ onSetSidebarOpen, sidebarOpen }: Props) {
  const { items } = useContext(CartContext);
  const initialMql = window.matchMedia(`(max-width: 767px)`);
  const isMobile = useIsMobile(initialMql.matches);

  let totalItems = 0;
  items
    ? items.map((item: Variation) => {
        const { quantity } = item;
        totalItems = totalItems + quantity;
      })
    : (totalItems = 0);

  let incon_sidebar = sidebarOpen ? "cancel" : "menu-1";

  return (
    <ApolloConsumer>
      {(client) => (
        <Grid.ContainerFluid>
          <CustomColumn md={10} xs={12}>
            <Grid.Row>
              <LogoContainer xs={7} md={4}>
                <StyledLogoLinkContainer to="/">
                  {!isMobile && <Img src={logoDesktop} />}
                  {isMobile && <Img src={logoMobile} />}
                </StyledLogoLinkContainer>
              </LogoContainer>
              <ActionLinksContainer xs={3} md={8}>
                <HeaderLinks to={"/faqs"}>
                  <span>Preguntas frecuentes</span>
                </HeaderLinks>
                <HeaderLinks to={"/contact"} className="no-border">
                  <span>Contáctenos</span>
                </HeaderLinks>
              </ActionLinksContainer>
              <BurguerContainer xs={2} md={0}>
                <Link to="#" onClick={onSetSidebarOpen}>
                  <Icon
                    color="white"
                    size="23px"
                    name={incon_sidebar}
                    className="kronos-icon"
                  />
                </Link>
              </BurguerContainer>
            </Grid.Row>
          </CustomColumn>
        </Grid.ContainerFluid>
      )}
    </ApolloConsumer>
  );
}

export default Header;
