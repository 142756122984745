import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import * as Grid from "../../components/Grid";
import Breadcrumb from "../../components/Breadcrumb";

import { withRouter } from "react-router-dom";
import {
  ContainerResume,
  ContainerTitle,
  BodyContainer,
  ContainerButton,
  DeliverContainer,
  InfoContainer,
  CallUsContainer,
  StyledLink,
} from "./style";

import { Spacer } from "../../components/Checkout/style";
//import { NavButton } from "../../components/Buttons";
//import ThemeContext from "../../context/ThemeContext";
import Stepper from "../../components/Checkout/components/Stepper";
import entregaImg from "../../assets/images/entrega.svg";
import imageState from "../../assets/images/check-gray.svg";
import callus from "../../assets/images/callus.svg";

function ResumePage() {
  const customer = JSON.parse(window.localStorage.getItem("customer") || "{}");
  const [message] = useState("");
  const [title] = useState(
    "Tu solicitud de suscripción se realizó exitosamente"
  );
  const [body] = useState([
    `<span>Una notificación se enviará al correo <b>${customer.email}</b></span>`,
    `<br/>`,
  ]);

  //const { primary, secondary } = useContext(ThemeContext);
  useEffect(() => {
    window.localStorage.removeItem("items");
    window.localStorage.removeItem("address");
    window.localStorage.removeItem("customer");
    window.localStorage.removeItem("deliverInfo");
  }, []);
  return (
    <Layout>
      <Grid.Container>
        <Spacer />
        <Grid.Row>
          <Grid.Column>
            <Breadcrumb
              links={[
                { label: "Inicio", to: "/" },
                {
                  label: "Mi suscripción",
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Stepper />
          </Grid.Column>
        </Grid.Row>
        <div className="animated fadeInLeft">
          <ContainerResume>
            <img src={imageState} />
            <ContainerTitle>
              <h2>{title}</h2>
              <br></br>
              <span>{message}</span>
            </ContainerTitle>
            <BodyContainer>
              {body &&
                body.map((item: string, i: number) => {
                  return (
                    <div dangerouslySetInnerHTML={{ __html: item }} key={i} />
                  );
                })}
            </BodyContainer>
            <DeliverContainer>
              <Grid.Row>
                <Grid.Column xs={12} sm={12}>
                  <InfoContainer align="center">
                    <img src={entregaImg} alt=""></img>
                    <span>
                      <p>
                        Recuerda que las entregas se realizarán los días viernes
                        de cada semana.
                      </p>
                      <br />
                      <p>
                        El valor de la suscripción se verá reflejado en tu
                        tarjeta de crédito Grande Table.
                      </p>
                    </span>
                  </InfoContainer>
                </Grid.Column>
              </Grid.Row>
            </DeliverContainer>
            <CallUsContainer>
              <a href="tel:02-500-1100">
                <img src={callus} />
              </a>
            </CallUsContainer>
            <ContainerButton>
              <StyledLink href="https://www.grandetable.com.ec/">
                IR A GRANDE TABLE
              </StyledLink>
            </ContainerButton>
          </ContainerResume>
        </div>
      </Grid.Container>
    </Layout>
  );
}

export default withRouter(ResumePage);
