import React from "react";

import { LoaderContainer, Container, Gif } from "./style";
import loaderGif from "../../assets/loader/loader.gif";

function Loader() {
  return (
    <Container>
      <LoaderContainer>
        <Gif src={loaderGif} />
      </LoaderContainer>
    </Container>
  );
}

export default Loader;
