import gql from 'graphql-tag'

const VALIDATE_GT_DOCUMENT_QUERY = gql`
  query validateDocument($filters: DocumentFilters!) {
    document(filters: $filters) {
      id
      document
      isGt
      email
      name
    }
  }
`

export { VALIDATE_GT_DOCUMENT_QUERY }
