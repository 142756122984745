import styled from 'styled-components'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import sizes from '../../../../styles/media'
import { ContainerFluid, Column } from '../../../../components/Grid'

interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const SectorModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  /* padding: 30px; */
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`

interface ModalProps {
  backgroundcolor?: string
  padding: string
}

export const ModalDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 20px;
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: #ffffff;
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }
  &.modalFooter {
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      width: 22px;
      height: 22px;
      transform: translate(-10px, 0) rotate(-45deg);
      background-color: #ffffff;
    }
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 4px;
  }
`

export const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`

export const LinkBreadcrum = styled(Link)`
  ${(props: Props) => {
    const style = `
      color:${props.fontcolor};
      font-weight: bold;
    `
    return style
  }};
`

export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      padding: 0 15px;
      font-weight: bold;
      `
  }};
`

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      color: ${props.fontcolor};
      border: solid 1px ${props.backgroundcolor};
      outline: none;
      cursor: pointer;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 4px;
      height: 100%;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
  width: 75px;
`

export const Ul = styled.ul`
  ${(props: Props) => {
    return `
      margin: 0;
      padding: 0;
      list-style: none;
      margin-bottom: 5px;
      li{
        letter-spacing: normal;
        height: 1.2rem;
        &::before {
        content: "•"; 
        color: ${props.fontcolor};
        font-size: large;
        width: 1em;
        margin-right: 7px;
        }
      }
    `
  }};
`

export const ItemContainer = styled.div`
  margin-bottom: 0.5rem;
  i {
    margin-right: 4px;
  }
`
export const ItemContainerDesktop = styled(ItemContainer)`
  display: none;
  @media ${sizes.md} {
    display: inline-block;
  }
`

export const ItemContainerMobile = styled(ItemContainer)`
  display: inline-block;
  @media ${sizes.md} {
    display: none;
  }
`

export const Name = styled.h2`
  font-family: 'DroidSerif';
  font-size: 2.14rem;
  font-weight: bold;
  margin: 0 0 12px;
  line-height: 1.17;
  letter-spacing: normal;
`

export const Title = styled.span`
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
`

export const Restaurant = styled.div`
  font-family: 'Muller-Bold';
  font-size: 1.3rem;
  color: #c59d5f;
  margin-bottom: 10px;
  text-transform: uppercase;
`

export const TitleSmall = styled.span`
  font-weight: bold;
  font-size: 11px;
`

export const Stock = styled.span`
  color: red;
`

export const Subtitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  color: #231f20;
  text-transform: uppercase;
  margin-bottom: 28px;
`
export const StyledContainerFluid = styled(ContainerFluid)`
  background-color: white;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  padding: 1rem 1.5rem;
  @media ${sizes.sm} {
    padding: 2rem;
  }
  @media ${sizes.md} {
    padding: 3rem;
  }
`

export const ContainerDescription = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  table {
    width: 100% !important;
    margin: auto;
    max-width: 100%;
    overflow: hidden;
    border-collapse: collapse;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    tr {
      td {
        white-space: pre-line !important;
      }
    }
  }
`

export const Datasheet = styled.div`
  width: 100%;
  height: 500px;
  background-color: #f1f1f1;
  margin-bottom: 1rem;
`

export const BannerPayment = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${sizes.sm} {
    flex-wrap: nowrap;
  }
`

export const ImgContainer = styled.div`
  margin-left: 0;
  margin-right: 0;
  display: none;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  img {
    max-width: 100%;
  }
  @media ${sizes.sm} {
    display: flex;
  }
`

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  @media ${sizes.md} {
    margin-bottom: 1rem;
    flex-direction: column;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media ${sizes.md} {
    margin-top: 0;
    width: 100%;
  }
`

export const CustomButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media ${sizes.md} {
    margin-top: 0;
    width: 50%;
  }
`

export const StyledColumn = styled(Column)`
  display: flex;
`

export const StyledColumnCustom = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const StyledBanner = styled.img`
  margin-top: 0;
`

export const TextContainer = styled.div`
  align-self: center;
  margin-right: 0;
  text-align: center;
  color: #ffffff;

  && h2 {
    font-size: 33px;
  }

  @media ${sizes.sm} {
    margin-right: 12%;
  }
`
export const ServiceContainer = styled.div`
  position: relative;
  justify-content: space-evenly;
  border-radius: 5px;
  background-color: #f7f7f7;
  border: 1px solid #e8e8e8;
  display: inline-flex;
  padding: 0 9px;
  margin-bottom: 12px;
  margin-top: 12px;
  font-family: Muller-Bold;
  width: 210px;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);

  @media ${sizes.sm} {
    justify-content: flex-start;
  }
`
export const StyleText = styled.div`
  margin: 10px;
`
export const StyleIcon = styled.div`
  align-self: center;
`

export const ContainerInfo = styled.div`
  display: contents;

  @media ${sizes.sm} {
    display: flex;
  }
`

export const Tooltip = styled.div`
  position: absolute;
  right: 15px;
  top: 40%;
  letter-spacing: 0.92px;
  font-family: 'Roboto';

  .icon-tooltip {
    display: none;
  }

  @media ${sizes.sm} {
    .icon-tooltip {
      display: block;
    }
  }

  .tooltip {
    font-size: 12px;
    display: block;
    position: relative;
  }

  .tooltip:hover:after {
    background: #231f20;
    border-radius: 5px;
    bottom: -10px;
    color: #fff;
    content: attr(title);
    left: 20px;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 170px;
  }

  .tooltip:hover:before {
    border: solid;
    border-color: #231f20 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 3px;
    content: '';
    left: 11px;
    position: absolute;
    z-index: 99;
    transform: rotate(90deg);
  }
`

export const ContainerAdvice = styled.div`
  align-self: center;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 25px;

  @media ${sizes.sm} {
    margin-left: -10px;
  }
`

export const StyleInfo = styled.div`
  background-color: #112340;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 12px;
`

export const OptionGroup = styled.div`
  padding: 0.5rem 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

// export const CustomCheckmark = styled.span`
//   position: absolute;
//   top: -0.3rem;
//   right: -2rem;
//   height: 1.3rem;
//   width: 1.3rem;
//   background-color: #eee;
//   border-radius: 50%;
//   border: solid 1px black;

//   &:after {
//     top: 4px;
//     left: 4px;
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     background: white;
//     content: '';
//     position: absolute;
//     display: none;
//   }
// `

export const CustomCheckmark = styled.div`
  width: 100%;
  max-width: 50px;
  border: solid 1px #ccc;
  padding: 10px;
  max-width: 85px;
  font-family: 'Muller-Bold';
`

export const CustomlOption = styled.input`
  margin: 0 0 0 0.5rem;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ ${CustomCheckmark}:after {
    display: block;
  }
`

export const CustomLabelOption = styled.label`
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  display: flex;
  background: #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  color: #75787b;

  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }

  ${CustomlOption}:checked ~ ${CustomCheckmark} {
    border: solid 1px #112340;
    background: #112340;
    color: #ffffff;
  }
`
export const PriceDescription = styled.p`
  color: #112340;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  @media ${sizes.sm} {
    font-size: 12px;
  }
`
