import React, { Component } from "react";
import { Query, QueryResult } from "react-apollo";
import Product from "../../model/Product";
import { GET_PRODUCTS_QUERY } from "./query";
import { Row, Container, Column } from "../Grid";
import { StyledColumn, Wrapper, Title } from "./style";
import Loader from "../loader";
import ProductCard from "../ProductCard";

interface DataProducts {
  products: {
    nodes: Product[];
  };
}

interface Filter {}

interface Options {
  limit: Number;
  sortBy: String;
}

interface Variables {
  filters: Filter;
  options: Options;
}

class Catalog extends Component {
  state = {
    selectedIndex: -1,
    items: 1,
    title: "Todos los restaurantes",
    resetCities: false,
  };
  setSelected = (index: any) => {
    this.setState({ selectedIndex: index });
  };
  render() {
    const productsVariables = {
      filters: {},
      options: {
        limit: this.state.items,
        sortBy: "priority",
      },
    };
    return (
      <Query<DataProducts, Variables>
        query={GET_PRODUCTS_QUERY}
        variables={productsVariables}
      >
        {({ loading, error, data }: QueryResult<DataProducts, Variables>) => {
          if (loading) {
            return <Loader />;
          }

          if (error) {
            return <p>Error</p>;
          }

          if (!data) {
            return <p>No Data</p>;
          }
          const products = data.products.nodes;
          const productsCategories: String[] = [];

          products.forEach((item: Product) => {
            const category =
              item.categories && item.categories.length
                ? item.categories[0]
                : null;

            if (category) {
              const foundItem = productsCategories.find(
                (itemSupplier: String) => category.name === itemSupplier
              );

              if (!foundItem) {
                productsCategories.push(category.name);
              }
            }
          });

          var sortOrder = [
            "Packs Grande Table",
            "Pack Parrilleros",
            "Entradas",
            "Platos Fuertes",
            "Postres",
          ];
          productsCategories.sort((a: any, b: any) => {
            const findItemAInAray = sortOrder.find((item: string) => {
              return item === b;
            });
            const findItemBInAray = sortOrder.find((item: string) => {
              return item === a;
            });
            if (!findItemAInAray) {
              return -1;
            }

            if (!findItemBInAray) {
              return 1;
            }
            return sortOrder.indexOf(a) - sortOrder.indexOf(b); // Substract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
          });
          return (
            <Wrapper>
              <Container>
                <Row>
                  <Column>
                    <Title>Suscríbete</Title>
                  </Column>
                </Row>
                <Row>
                  {products &&
                    products.map((product: Product, index: number) => {
                      return (
                        <StyledColumn
                          xs={12}
                          xsm={12}
                          sm={12}
                          md={12}
                          lg={12}
                          key={`product-${index}`}
                        >
                          <ProductCard
                            product={product}
                            selectedIndex={this.state.selectedIndex}
                            index={index}
                            setSelected={this.setSelected}
                          />
                        </StyledColumn>
                      );
                    })}
                </Row>
              </Container>
            </Wrapper>
          );
        }}
      </Query>
    );
  }
}

export default Catalog;
