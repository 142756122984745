import React, { useContext, useState, useEffect } from "react";
import gql from "graphql-tag";
import {
  Query,
  QueryResult,
  graphql,
  ApolloConsumer,
  withApollo,
} from "react-apollo";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import Collapsible from "react-collapsible";
import { withRouter } from "react-router-dom";
import { ValueType } from "react-select/lib/types";

import Checkout from "../../components/Checkout";
import Loader from "../../components/loader";
import * as Grid from "../../components/Grid";
import { Input, Error, Textarea } from "../../components/FormElements";
import { Spacer } from "../../components/Global/style";
import { Button, NavButton } from "../../components/Buttons";
import TitleSummary from "../../components/Checkout/components/TitleSummary";
import ProductSummary from "../../components/Checkout/components/ProductSummary";
import DateDelivery from "../../components/Checkout/components/DateDelivery";
import Address from "../../model/Address";

import {
  ContainerForm,
  FlexCol,
  ContainerElements,
  TextAreaElements,
  Element,
  CountContainer,
  StyledNewChoice,
  StyledAction,
  StyledNewData,
  StyledFlexCol,
} from "../Shipping/style";

import {
  ButtonContainer,
  Circle,
  ModalContent,
  CouponModal,
} from "../../components/ProductsList/style";
import { ModalDataContainer } from '../Product/components/Description/style'
import { Row, Column } from '../../components/Grid'

import ThemeContext from "../../context/ThemeContext";
import CartContext from "../../context/CartContext";

import { useIsMobile, useIsIpad } from "../../helpers/resize";
import {
  customStyle,
  errorStyle,
  identificationTypeOption,
} from "../../helpers/selectProps";
import { Validate } from "../../helpers/inputValidations";

import { validateGtMember } from "../../helpers/gtValidation";
import TitleSummaryResponsive from "../../components/Checkout/components/TitleSummaryResponsive";


import contactImg from '../../assets/images/contact.svg'
import timesIcon from '../../assets/images/times.svg'

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $mobile: String
    $document: String
    $docUpdated: Int!
    $deliverName: String
    $deliverPhone: String
    $deliverEmail: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      mobile: $mobile
      document: $document
      docUpdated: $docUpdated
      deliverName: $deliverName
      deliverPhone: $deliverPhone
      deliverEmail: $deliverEmail
    ) {
      token
    }
  }
`;

const GET_STATES = gql`
  query getStates($filters: StateFilters, $options: StateOptions) {
    states(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`;

const GET_CITIES = gql`
  query getCitiesByStateId(
    $stateId: String!
    $filters: CityFilters
    $options: CityOptions
  ) {
    citiesByState(stateId: $stateId, filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`;

const GET_ZONES = gql`
  query getZonesByCityId(
    $cityId: ID!
    $filters: ZoneFilters
    $options: ZoneOptions
  ) {
    zonesByCity(cityId: $cityId, filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`;

type Locations = {
  id: string;
  name: string;
  postalcodeUrbano?: string;
  value?: string;
  label?: string;
};

function Shipping({ history, mutate, client }: any) {
  // Notify context
  const { forceShowNotification, onSetLoaded, loaded } = useContext(
    CartContext
  );
  const [identificationState, setIdentificationState] = useState({
    disable: true,
    length: 20,
    type: "",
    value: "",
    error: false,
    style: customStyle,
  });
  //Loading initial values
  let citiesSelected = JSON.parse(
    window.localStorage.getItem("cities") || "[]"
  );
  let zonesSelected = JSON.parse(window.localStorage.getItem("zones") || "[]");

  const [citiesState, setCitiesState] = useState(
    citiesSelected ? citiesSelected : [{ value: "", label: "" }]
  );
  const [zonesState, setZonesState] = useState(
    zonesSelected ? zonesSelected : [{ value: "", label: "" }]
  );

  const [modalOpen, setModalOpen] = useState(false);

  // Display media hooks
  const { primary, secondary } = useContext(ThemeContext);
  const initialMql = window.matchMedia(`(max-width: 767px)`);
  const isMobile = useIsMobile(initialMql.matches);
  const initialMqlIpad = window.matchMedia(`(max-width: 1024px)`);
  const isIpad = useIsIpad(initialMqlIpad.matches);
  // get initial values of localstorage
  const {
    province: initialState,
    provinceName,
    city: initialCity,
    cityName,
    street1: initialStreet1,
    street2: initialStreet2,
    number: initialNumber,
    zone: initialZone,
    zoneName,
    reference: initialReference,
    firstName: initialFirstName,
    lastName: initialLastName,
    phone: initialPhone,
    email: initialEmail,
    identificationNumber: initialIdentificationNumber,
    identificationType: initialIdentificationType,
  }: Address = JSON.parse(window.localStorage.getItem("address") || "{}");
  // Province, City, Zone selected Hooks
  const [provinceState, setProvinceState] = useState({
    value: initialState ? initialState : "",
    label: initialState ? provinceName : "Selecciona una provincia",
    error: false,
    style: customStyle,
  });
  const [cityState, setCityState] = useState({
    value: initialCity ? initialCity : "",
    label: initialCity ? cityName : "Selecciona una ciudad",
    error: false,
    style: customStyle,
  });
  const [zoneState, setZoneState] = useState({
    value: initialZone ? initialZone : "",
    label: initialZone ? zoneName : "Ingresa un sector",
    error: false,
    style: customStyle,
  });
  // define hooks for every input

  const [idNumberState, setIdNumber] = useState({
    error: false,
    message: "",
    value: initialIdentificationNumber ? initialIdentificationNumber : "",
  });
  const [street1State, setStreet1] = useState({
    error: false,
    message: "",
    value: initialStreet1 ? initialStreet1 : "",
  });
  const [street2State, setStreet2] = useState({
    error: false,
    message: "",
    value: initialStreet2 ? initialStreet2 : "",
  });
  const [numberState, setNumber] = useState({
    error: false,
    message: "",
    value: initialNumber ? initialNumber : "",
  });

  const [referenceState, setReference] = useState({
    error: false,
    message: "",
    value: initialReference ? initialReference : "",
  });
  const [firstNameState, setFirstName] = useState({
    error: false,
    message: "",
    value: initialFirstName ? initialFirstName : "",
  });
  const [lastNameState, setLastName] = useState({
    error: false,
    message: "",
    value: initialLastName ? initialLastName : "",
  });
  const [phoneState, setPhone] = useState({
    error: false,
    message: "",
    value: initialPhone ? initialPhone : "",
  });
  const [emailState, setEmail] = useState({
    error: false,
    message: "",
    value: initialEmail ? initialEmail : "",
  });

  const [count, setCount] = useState(
    initialReference ? initialReference.length : 0
  );

  // onchange function on state select
  const selectState = async (optionSelected: any) => {
    setProvinceState({
      value: optionSelected.value,
      label: optionSelected.label,
      error: false,
      style: customStyle,
    });

    await setCityState({
      value: "",
      label: "Selecciona una ciudad",
      error: false,
      style: customStyle,
    });
    await setZoneState({
      value: "",
      label: "Ingresa un sector",
      error: false,
      style: customStyle,
    });
    persistData(optionSelected.value, "province");
    persistData(optionSelected.label, "provinceName");
  };
  // onchange function on city select
  const selectCity = (optionSelected: any, actionMeta: any) => {
    if (actionMeta.action != "clear") {
      setCityState({
        value: optionSelected.value,
        label: optionSelected.label,
        error: false,
        style: customStyle,
      });
      setZoneState({
        error: false,
        label: "Ingresa un sector",
        value: "",
        style: customStyle,
      });
      persistData(optionSelected.value, "city");
      persistData(optionSelected.label, "cityName");
    }
  };
  // onchange function on zone select
  const selectZone = (optionSelected: any, actionMeta: any) => {
    if (actionMeta.action != "clear") {
      setZoneState({
        ...zoneState,
        error: false,
        label: optionSelected.label,
        value: optionSelected.value,
        style: customStyle,
      });
      persistData(optionSelected.value, "zone");
      persistData(optionSelected.label, "zoneName");
    } else {
      setZoneState({
        error: true,
        label: "",
        value: "",
        style: errorStyle,
      });
    }
  };

  const validateSelects = () => {
    if (provinceState.value.length <= 0) {
      setProvinceState({
        ...provinceState,
        error: true,
        style: errorStyle,
      });
    }
    if (cityState.value.length <= 0) {
      setCityState({
        ...cityState,
        error: true,
        style: errorStyle,
      });
    }
    if (zoneState.value.length <= 0) {
      setZoneState({
        ...zoneState,
        error: true,
        style: errorStyle,
      });
    }
  };

  // validate every input and select data
  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value);
    switch (id) {
      case "identificationNumber":
        dataValidated = Validate(
          value,
          identificationState.value,
          identificationState.length
        );
        /* if (!dataValidated.error && value.length >= 10) {
          validateGtMember(value).then((response: any) => {
            setIdNumber(response)
          })
        } else {
          setIdNumber(dataValidated)
        } */
        setIdNumber(dataValidated);
        break;
      case "street1":
        setStreet1(dataValidated);
        break;
      case "street2":
        setStreet2(dataValidated);
        break;
      case "number":
        setNumber(dataValidated);
        break;
      case "reference":
        dataValidated = Validate(value, "", 50);
        setReference(dataValidated);
        break;
      case "firstName":
        dataValidated = Validate(value, "characters");
        setFirstName(dataValidated);
        break;
      case "lastName":
        dataValidated = Validate(value, "characters");
        setLastName(dataValidated);
        break;
      case "phone":
        dataValidated = Validate(value, "numeric", 10);
        setPhone(dataValidated);
        break;
      case "email":
        dataValidated = Validate(value, "email", 60);
        setEmail(dataValidated);
        break;
    }
    if (dataValidated.error) return true;
    else return false;
  };

  // select identification number validate and persist
  const handleChange = (selectedOption: any, isEffect: boolean) => {
    if (isEffect) {
      validateData(idNumberState.value, "identificationNumber");
    }
    switch (selectedOption.value) {
      case "CI":
        setIdentificationState({
          disable: false,
          length: 10,
          type: "Cédula de Identidad",
          value: "CI",
          error: false,
          style: customStyle,
        });
        break;
      case "PPN":
        setIdentificationState({
          disable: false,
          length: 20,
          type: "Pasaporte",
          value: "PPN",
          error: false,
          style: customStyle,
        });
        break;
      case "RUC":
        setIdentificationState({
          disable: false,
          length: 13,
          type: "RUC",
          value: "RUC",
          error: false,
          style: customStyle,
        });
        break;
    }
    persistData(selectedOption.value, "identificationType");
    validateData(idNumberState.value, "identificationNumber");
  };

  // compare data from local storage and set like default option
  let selected: ValueType<{ value: string; label: string }> = {
    value: "",
    label: "Selecciona una opción",
  };
  identificationTypeOption.forEach((item) => {
    if (item.value == initialIdentificationType) {
      selected = item;
    }
  });

  const getSelected: ValueType<{ value: string; label: string }> = selected;

  const validateAllInputs = () => {
    if (identificationState.value.length <= 0) {
      setIdentificationState({
        ...identificationState,
        error: true,
        style: errorStyle,
      });
    }
    validateSelects();
    validateData(idNumberState.value, "identificationNumber");
    validateData(street1State.value, "street1");
    validateData(street2State.value, "street2");
    validateData(numberState.value, "number");
    validateData(referenceState.value, "reference");
    validateData(firstNameState.value, "firstName");
    validateData(lastNameState.value, "lastName");
    validateData(phoneState.value, "phone");
    validateData(emailState.value, "email");
    return true;
  };

  // Perssit data in local storage
  const persistData = (value: string, id: string) => {
    if (validateData(value, id) == false) {
      const address = JSON.parse(
        window.localStorage.getItem("address") || "{}"
      );
      address[id] = value;
      window.localStorage.setItem("address", JSON.stringify(address));
    }
  };
  // Filters an Options to
  const filters = { s: "" };
  const options = { limit: 100 };
  const loadZones = async () => {
    const { city: currentCity } = JSON.parse(
      window.localStorage.getItem("address") || "{}"
    );
    const { data } = await client.query({
      query: GET_ZONES,
      variables: { cityId: currentCity, filters, options },
    });
    const zones = data.zonesByCity.nodes.map((zone: Locations) => {
      return {
        value: zone.id,
        label: zone.name,
      };
    });
    window.localStorage.setItem("zones", JSON.stringify(zones));
    setZonesState(zones);
  };

  useEffect(() => {
    loadZones();
  }, []);

  useEffect(() => {
    if (getSelected.label != "Selecciona una opción") {
      handleChange(getSelected, false);
    }
  }, [initialIdentificationType]);

  // Onclick event in next button
  const goToBilling = () => {
    validateAllInputs();
    if (
      !provinceState.value ||
      !cityState.value ||
      !street1State.value ||
      !street2State.value ||
      !numberState.value ||
      !zoneState.value ||
      !referenceState.value ||
      !firstNameState.value ||
      !lastNameState.value ||
      !phoneState.value ||
      !emailState.value ||
      provinceState.error ||
      cityState.error ||
      street1State.error ||
      street2State.error ||
      numberState.error ||
      zoneState.error ||
      referenceState.error ||
      firstNameState.error ||
      lastNameState.error ||
      phoneState.error ||
      emailState.error
    ) {
      forceShowNotification &&
        forceShowNotification({
          type: "alert",
          message:
            "Para avanzar, verifique que todos los campos sean los correctos.",
        });
      return;
    }

    const address: Address = {
      city: cityState.value,
      cityName: cityState.label,
      number: numberState.value,
      reference: referenceState.value,
      province: provinceState.value,
      provinceName: provinceState.label,
      street1: street1State.value,
      street2: street2State.value,
      zone: zoneState.value,
      zoneName: zoneState.label,
      firstName: firstNameState.value,
      lastName: lastNameState.value,
      email: emailState.value,
      phone: phoneState.value,
      identificationNumber: idNumberState.value,
      identificationType: identificationState.value,
    };
    validateGtMember(idNumberState.value)
      .then((response: any) => {
        if (response.error) {
          setModalOpen(true);
          return false;
        } else {
          window.localStorage.setItem("address", JSON.stringify(address));
          history.push("/billing");
          return true;
        }
      })
      .catch(() => {
        setModalOpen(true);
        return false;
      });
  };

  return loaded ? (
    <Query
      variables={{ filters, options }}
      query={GET_STATES}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<any>) => {
        if (loading) {
          return (
            <Checkout>
              <Grid.Container>
                <Loader />
              </Grid.Container>
            </Checkout>
          );
        }

        if (error) {
          return <div>error</div>;
        }

        if (!data) {
          return <div>No hay data</div>;
        }

        const { nodes } = data.states;
        const states = nodes.map((state: Locations) => {
          state.value = state.id;
          state.label = state.name;
          return state;
        });
        // setProvincesState(states);
        return (
          <>
            <Checkout>
              <Grid.Row>
                {isMobile && (
                  <Grid.Column>
                    <ContainerElements>
                      <Collapsible
                        trigger={
                          <TitleSummaryResponsive iconName="down-open" />
                        }
                        triggerWhenOpen={
                          <TitleSummaryResponsive iconName="up-open" />
                        }
                      >
                        <ProductSummary />
                      </Collapsible>
                    </ContainerElements>
                  </Grid.Column>
                )}
                {isMobile && (
                  <Grid.Column>
                    <div style={{ marginBottom: "1rem" }}>
                      <DateDelivery />
                    </div>
                  </Grid.Column>
                )}

                <Grid.Column md={isMobile || isIpad ? 12 : 9}>
                  <div className="animated fadeInLeft">
                    <Grid.Row>
                      <Grid.Column>
                        <h3>Persona de contacto para entrega</h3>
                      </Grid.Column>
                    </Grid.Row>
                    <ContainerForm backgroundcolor={secondary}>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <span>* Tipo de Identificación</span>
                            <Select
                              options={identificationTypeOption}
                              onChange={(e: any) => handleChange(e, true)}
                              defaultValue={getSelected}
                              styles={identificationState.style}
                            />
                            {identificationState.error ? (
                              <Error>
                                Campo obligatorio, no puede estar vacio
                              </Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(
                                  e.target.value,
                                  "identificationNumber"
                                );
                              }}
                              onChange={(e: any) => {
                                validateData(
                                  e.target.value,
                                  "identificationNumber"
                                );
                              }}
                              id="identificationNumber"
                              label="Identificación"
                              type="text"
                              required
                              disabled={identificationState.disable}
                              error={idNumberState.error}
                              value={idNumberState.value}
                            />
                            {idNumberState.error ? (
                              <Error>{idNumberState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "firstName");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "firstName");
                              }}
                              id="firstName"
                              type="text"
                              label="Nombre"
                              borderColor="#2684FF"
                              required
                              error={firstNameState.error}
                              value={firstNameState.value}
                            />
                            {firstNameState.error ? (
                              <Error>{firstNameState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "lastName");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "lastName");
                              }}
                              id="lastName"
                              type="text"
                              label="Apellido"
                              borderColor="#2684FF"
                              required
                              error={lastNameState.error}
                              value={lastNameState.value}
                            />
                            {lastNameState.error ? (
                              <Error>{lastNameState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "phone");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "phone");
                              }}
                              id="phone"
                              type="text"
                              label="Teléfono de Contacto"
                              borderColor="#2684FF"
                              required
                              error={phoneState.error}
                              value={phoneState.value}
                            />
                            {phoneState.error ? (
                              <Error>{phoneState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "email");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "email");
                              }}
                              id="email"
                              type="text"
                              label="Correo Electrónico"
                              borderColor="#2684FF"
                              maxLength={60}
                              required
                              error={emailState.error}
                              value={emailState.value}
                            />
                            {emailState.error ? (
                              <Error>{emailState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                    </ContainerForm>
                    <Grid.Row>
                      <Grid.Column md={12}>
                        <h3>Dirección de envío</h3>
                      </Grid.Column>
                    </Grid.Row>
                    <ContainerForm backgroundcolor={secondary}>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol id="flexcol">
                            <ApolloConsumer>
                              {(client) => (
                                <>
                                  <span>* Provincia</span>
                                  <Select
                                    id="province"
                                    isDisabled={true}
                                    options={states}
                                    onChange={async (optionSelected: any) => {
                                      const stateId = optionSelected.value;
                                      const { data } = await client.query({
                                        query: GET_CITIES,
                                        variables: {
                                          stateId,
                                          filters,
                                          options,
                                        },
                                      });
                                      const cities = data.citiesByState.nodes.map(
                                        (city: Locations) => {
                                          return {
                                            value: city.id,
                                            label: city.name,
                                          };
                                        }
                                      );
                                      window.localStorage.setItem(
                                        "cities",
                                        JSON.stringify(cities)
                                      );
                                      setCitiesState(cities);
                                      selectState(optionSelected);
                                    }}
                                    value={provinceState}
                                    styles={provinceState.style}
                                    noOptionsMessage={() =>
                                      "No existe la provincia."
                                    }
                                    autoFocus={false}
                                  />
                                  <>
                                    {provinceState.error ? (
                                      <Error>
                                        Campo obligatorio, no puede estar vacio
                                      </Error>
                                    ) : (
                                      <Spacer />
                                    )}
                                  </>
                                </>
                              )}
                            </ApolloConsumer>
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <ApolloConsumer>
                              {(client) => (
                                <>
                                  <span>* Ciudad</span>
                                  <Select
                                    id="city"
                                    isDisabled={true}
                                    options={citiesState}
                                    onChange={async (
                                      optionSelected: any,
                                      actionMeta: any
                                    ) => {
                                      const cityId = optionSelected.value;
                                      const { data } = await client.query({
                                        query: GET_ZONES,
                                        variables: { cityId, filters, options },
                                      });
                                      const zones = data.zonesByCity.nodes.map(
                                        (zone: Locations) => {
                                          return {
                                            value: zone.id,
                                            label: zone.name,
                                          };
                                        }
                                      );
                                      window.localStorage.setItem(
                                        "zones",
                                        JSON.stringify(zones)
                                      );
                                      selectCity(optionSelected, actionMeta);
                                      setZonesState(zones);
                                    }}
                                    value={cityState}
                                    styles={cityState.style}
                                    noOptionsMessage={() =>
                                      "No existe la ciudad."
                                    }
                                    autoFocus={false}
                                  />
                                  <>
                                    {cityState.error ? (
                                      <Error>
                                        Campo obligatorio, no puede estar vacio
                                      </Error>
                                    ) : (
                                      <Spacer />
                                    )}
                                  </>
                                </>
                              )}
                            </ApolloConsumer>
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <StyledFlexCol empty={zonesState.length === 1}>
                            <span>* Sector</span>
                            <CreatableSelect
                              isClearable
                              styles={zoneState.style}
                              options={zonesState}
                              onChange={selectZone}
                              value={zoneState}
                              noOptionsMessage={() => "Digite un sector."}
                              formatCreateLabel={(value: string) => (
                                <StyledNewChoice>
                                  <StyledNewData>{value}</StyledNewData>{" "}
                                  <StyledAction> + Agregar</StyledAction>
                                </StyledNewChoice>
                              )}
                              autoFocus={false}
                            />
                            {zoneState.error ? (
                              <Error>
                                {"Campo obligatorio, no puede estar vacio"}
                              </Error>
                            ) : (
                              <Spacer />
                            )}
                          </StyledFlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "street1");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "street1");
                              }}
                              id="street1"
                              type="text"
                              label="Calle Principal"
                              borderColor="#2684FF"
                              required
                              value={street1State.value}
                              error={street1State.error}
                            />
                            {street1State.error ? (
                              <Error>{street1State.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "street2");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "street2");
                              }}
                              id="street2"
                              type="text"
                              label="Calle Secundaria"
                              borderColor="#2684FF"
                              required
                              error={street2State.error}
                              value={street2State.value}
                            />
                            {street2State.error ? (
                              <Error>{street2State.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, "number");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "number");
                              }}
                              id="number"
                              type="text"
                              label="Numeración"
                              borderColor="#2684FF"
                              required
                              error={numberState.error}
                              value={numberState.value}
                            />
                            {numberState.error ? (
                              <Error>{numberState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Textarea
                              onBlur={(e: any) => {
                                persistData(e.target.value, "reference");
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, "reference");
                              }}
                              onKeyUp={(e: any) => {
                                setCount(referenceState.value.length);
                              }}
                              id="reference"
                              maxLength={50}
                              rows={3}
                              label="Detalle de vivienda o referencia"
                              borderColor="#2684FF"
                              placeholder="Ingresa una referencia de tu vivienda (Edificio, No. Piso, Departamento, etc)."
                              required
                              error={referenceState.error}
                              value={referenceState.value}
                            />
                            <TextAreaElements>
                              <Element>
                                {referenceState.error ? (
                                  <Error>{referenceState.message}</Error>
                                ) : (
                                  <Spacer />
                                )}
                              </Element>
                              <CountContainer>{count}/50</CountContainer>
                            </TextAreaElements>
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                    </ContainerForm>
                  </div>
                </Grid.Column>
                {!isMobile && (
                  <Grid.Column md={isIpad ? 6 : 3}>
                    <TitleSummary />
                    <ContainerElements>
                      <ProductSummary />
                    </ContainerElements>
                    {!isIpad && (
                      <>
                        <ContainerElements>
                          <DateDelivery />
                        </ContainerElements>
                        <ContainerElements>
                          <Button
                            backgroundColor={primary}
                            color={secondary}
                            onClick={() => {
                              goToBilling();
                            }}
                            id={"MKTPL_SHIP_NEXT"}
                          >
                            Siguiente
                          </Button>
                        </ContainerElements>
                        <ContainerElements>
                          <NavButton
                            to="/"
                            backgroundColor="#f7f7f7"
                            color={primary}
                            bordercolor={primary}
                            third={"#000000"}
                            onClick={() => {
                              onSetLoaded && onSetLoaded(false);
                            }}
                          >
                            Atrás
                          </NavButton>
                        </ContainerElements>
                      </>
                    )}
                  </Grid.Column>
                )}
                {!isMobile && isIpad && (
                  <Grid.Column md={6} style={{ margin: "3.5rem 0 0" }}>
                    <ContainerElements>
                      <DateDelivery />
                    </ContainerElements>
                    <ContainerElements>
                      <Button
                        backgroundColor={primary}
                        color={secondary}
                        onClick={() => {
                          goToBilling();
                        }}
                        id={"MKTPL_SHIP_NEXT"}
                      >
                        Siguiente
                      </Button>
                    </ContainerElements>
                    <ContainerElements>
                      <NavButton
                        to="/"
                        backgroundColor="#f7f7f7"
                        color={primary}
                        bordercolor={primary}
                        third={"#000000"}
                        onClick={() => {
                          onSetLoaded && onSetLoaded(false);
                        }}
                      >
                        Atrás
                      </NavButton>
                    </ContainerElements>
                  </Grid.Column>
                )}
                {isMobile && (
                  <Grid.Column>
                    <ContainerElements>
                      <Button
                        backgroundColor={primary}
                        color={secondary}
                        onClick={() => {
                          goToBilling();
                        }}
                        id={"MKTPL_SHIP_NEXT"}
                      >
                        Siguiente
                      </Button>
                    </ContainerElements>
                    <ContainerElements>
                      <NavButton
                        to="/"
                        backgroundColor="#f7f7f7"
                        color={primary}
                        bordercolor={primary}
                        third={"#000000"}
                        onClick={() => {
                          onSetLoaded && onSetLoaded(false);
                        }}
                      >
                        Atrás
                      </NavButton>
                    </ContainerElements>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Checkout>

            <CouponModal
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: "auto",
                  padding: "15px",
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  zIndex: 100,
                },
              }}
              ariaHideApp={false}
              isOpen={modalOpen}
            >
              <>
                <ModalDataContainer
                  padding={"30px 30px 0px 30px"}
                  backgroundcolor={"#ffffff"}
                >
                  <Row>
                    <Column>
                      <Circle>
                        <img src={timesIcon} alt="" />
                      </Circle>
                    </Column>
                  </Row>
                  <Row>
                    <ModalContent>
                      <h1>Error en la validación</h1>
                      <p>
                        Lo sentimos, este beneficio es exclusivo para socios del
                        Club Gourmet Grande Table.
                      </p>
                      <p>Para más información comunícate con nosotros</p>

                      <img src={contactImg} alt="" />
                    </ModalContent>
                  </Row>
                </ModalDataContainer>
                <ModalDataContainer
                  padding={"10px 30px 10px 30px"}
                  backgroundcolor={"#f5f5f5"}
                  className={"modalFooter"}
                >
                  <Row className="centered">
                    <Column sm={6}>
                      <ButtonContainer>
                        <Button
                          backgroundColor={"#c59d5f"}
                          color={secondary}
                          onClick={() => setModalOpen(false)}
                        >
                          ENTENDIDO
                        </Button>
                      </ButtonContainer>
                    </Column>
                  </Row>
                </ModalDataContainer>
              </>
            </CouponModal>
          </>
        );
      }}
    </Query>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
      <ProductSummary />
    </Checkout>
  );
}

const AddTracklWithMutation = graphql(ADD_TRACKING_MUTATION)(
  withRouter(withApollo(Shipping))
);
export default AddTracklWithMutation;
