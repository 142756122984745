import { createContext } from 'react'

export type Theme = {
  primary: string
  secondary: string
  third?: string
  btnPrimary?: string
  btnSecondary?: string
}

const ThemeContext = createContext<Theme>({
  primary: '#1428a0',
  secondary: '#ffffff',
  third: '#f7f7f7',
  btnPrimary: '#000000',
  btnSecondary: '#FFFFFF'
})

export default ThemeContext
