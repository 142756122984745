import React, { useContext, useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import { withRouter } from "react-router-dom";
import { ValueType } from "react-select/lib/types";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import Checkout from "../../components/Checkout";
import * as Grid from "../../components/Grid";

import { NavButton } from "../../components/Buttons";
import ProductSummary from "../../components/Checkout/components/ProductSummary";
import TitleSummary from "../../components/Checkout/components/TitleSummary";
import DateDelivery from "../../components/Checkout/components/DateDelivery";
import { Input, Error, Textarea } from "../../components/FormElements";
import { Spacer } from "../../components/Global/style";
import { Button } from "../../components/Buttons";

import ThemeContext from "../../context/ThemeContext";
import CartContext from "../../context/CartContext";
import Loader from "../../components/loader";

import { useIsMobile, useIsIpad } from "../../helpers/resize";
import {
  customStyle,
  errorStyle,
  identificationTypeOptions,
} from "../../helpers/selectProps";
import { Validate } from "../../helpers/inputValidations";

import Customer from "../../model/Customer";
import Address from "../../model/Address";

import {
  ContainerForm,
  FlexCol,
  ContainerElements,
  TextAreaElements,
  Element,
  CountContainer,
} from "./style";
import { TermsContainer, Checkbox } from "../Payment/style";
import Icon from "../../components/Icon";
import Select from "react-select";
import TitleSummaryResponsive from "../../components/Checkout/components/TitleSummaryResponsive";

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $mobile: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      mobile: $mobile
      document: $document
      docUpdated: $docUpdated
    ) {
      token
    }
  }
`;

type inputProps = {
  error: boolean;
  message: string;
  hide: boolean;
  value: string;
  fillColor?: string;
};

function Billing({ history, mutate }: any) {
  const fillColor = "lightyellow";
  // Notify context
  const {
    forceShowNotification,
    onSetLoaded,
    loaded,
    updateItems,
  } = useContext(CartContext);

  const [identificationState, setIdentificationState] = useState({
    disable: true,
    length: 20,
    type: "",
    value: "",
    error: false,
    style: customStyle,
  });
  const [useShipping, setUseShipping] = useState(false);
  // Display media hooks
  const initialMql = window.matchMedia(`(max-width: 767px)`);
  const isMobile = useIsMobile(initialMql.matches);
  const initialMqlIpad = window.matchMedia(`(max-width: 1024px)`);
  const isIpad = useIsIpad(initialMqlIpad.matches);
  // Theme context
  const { primary, secondary } = useContext(ThemeContext);
  // Get initial values from localstorage (Address Type)
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
  }: Address = JSON.parse(window.localStorage.getItem("address") || "{}");
  // Get initial values from localstorage (Customer Type)
  const {
    firstName: initialFirstNameCustomer,
    lastName: initialLastNameCustomer,
    businessName: initialBusinessNameCustomer,
    mobile: initialMobileCustomer,
    email: initialEmailCustomer,
    address: initialAddress,
    identificationNumber: initialIdentificationNumber,
    identificationType: initialIdentificationType,
  }: Customer = JSON.parse(window.localStorage.getItem("customer") || "{}");

  // Define hooks for every input
  const [idNumberState, setIdNumber] = useState({
    error: false,
    message: "",
    value: initialIdentificationNumber ? initialIdentificationNumber : "",
  });
  const [firstNameState, setFirstName] = useState<inputProps>({
    error: false,
    message: "",
    hide: false,
    value: initialFirstNameCustomer
      ? initialFirstNameCustomer
      : initialFirstName
      ? initialFirstName
      : "",
    fillColor: initialFirstNameCustomer
      ? "#FFF"
      : initialFirstName
      ? fillColor
      : "#FFF",
  });
  const [lastNameState, setLastName] = useState<inputProps>({
    error: false,
    message: "",
    hide: false,
    value: initialLastNameCustomer
      ? initialLastNameCustomer
      : initialLastName
      ? initialLastName
      : "",
    fillColor: initialLastNameCustomer
      ? "#FFF"
      : initialLastName
      ? fillColor
      : "#FFF",
  });

  const [businessNameState, setBusinessName] = useState({
    error: false,
    message: "",
    hide: true,
    value: initialBusinessNameCustomer ? initialBusinessNameCustomer : "",
  });

  const [mobileState, setMobile] = useState<inputProps>({
    error: false,
    message: "",
    hide: false,
    value: initialMobileCustomer ? initialMobileCustomer : "",
  });

  const [emailState, setEmail] = useState<inputProps>({
    error: false,
    message: "",
    hide: false,
    value: initialEmailCustomer
      ? initialEmailCustomer
      : initialEmail
      ? initialEmail
      : "",
    fillColor: initialEmailCustomer
      ? "#FFF"
      : initialEmail
      ? fillColor
      : "#FFF",
  });

  const [addressState, setAddress] = useState({
    error: false,
    message: "",
    hide: false,
    value: initialAddress ? initialAddress : "",
  });

  // Validate input data
  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value);
    switch (id) {
      case "identificationNumber":
        dataValidated = Validate(
          value,
          identificationState.value,
          identificationState.length
        );
        /* if (!dataValidated.error && value.length >= 10) {
          validateGtMember(value).then((response: any) => {
            setIdNumber(response)
          })
        } else {
          setIdNumber(dataValidated)
        } */
        setIdNumber(dataValidated);
        break;
      case "firstName":
        dataValidated = Validate(value, "characters");
        setFirstName(dataValidated);
        break;
      case "lastName":
        dataValidated = Validate(value, "characters");
        setLastName(dataValidated);
        break;
      case "businessName":
        dataValidated = Validate(value);
        setBusinessName(dataValidated);
        break;
      case "mobile":
        dataValidated = Validate(value, "mobile", 10, "", false);
        setMobile(dataValidated);
        break;
      case "email":
        dataValidated = Validate(value, "email", 60);
        setEmail(dataValidated);
        break;
      case "address":
        dataValidated = Validate(value, "address", 50);
        setAddress(dataValidated);
        break;
    }
    if (dataValidated.error) return true;
    else return false;
  };

  const [count, setCount] = useState(
    initialAddress ? initialAddress.length : 0
  );

  // set input onblur in localstorage
  const persistData = (value: string, id: string) => {
    if (validateData(value, id) == false) {
      const customer = JSON.parse(
        window.localStorage.getItem("customer") || "{}"
      );
      customer[id] = value;
      window.localStorage.setItem("customer", JSON.stringify(customer));
    }
  };
  // select identification number validate and persist
  const handleChange = (selectedOption: any, isEffect: boolean) => {
    //setSelected(selectedOption)
    if (isEffect) {
      validateData(idNumberState.value, "identificationNumber");
    }
    switch (selectedOption.value) {
      case "CI":
        setIdentificationState({
          disable: false,
          length: 10,
          type: "Cédula de Identidad",
          value: "CI",
          error: false,
          style: customStyle,
        });
        setBusinessName({
          error: false,
          message: "",
          hide: true,
          value: businessNameState.value,
        });
        setFirstName({
          error: false,
          message: "",
          hide: false,
          value: firstNameState.value,
          fillColor: firstNameState.fillColor,
        });
        setLastName({
          error: false,
          message: "",
          hide: false,
          value: lastNameState.value,
          fillColor: lastNameState.fillColor,
        });
        break;
      case "PPN":
        setIdentificationState({
          disable: false,
          length: 20,
          type: "Pasaporte",
          value: "PPN",
          error: false,
          style: customStyle,
        });
        setBusinessName({
          error: false,
          message: "",
          hide: true,
          value: businessNameState.value,
        });
        setFirstName({
          error: false,
          message: "",
          hide: false,
          value: firstNameState.value,
          fillColor: firstNameState.fillColor,
        });
        setLastName({
          error: false,
          message: "",
          hide: false,
          value: lastNameState.value,
          fillColor: lastNameState.fillColor,
        });
        break;
      case "RUC":
        setIdentificationState({
          disable: false,
          length: 13,
          type: "RUC",
          value: "RUC",
          error: false,
          style: customStyle,
        });
        setBusinessName({
          error: false,
          message: "",
          hide: false,
          value: businessNameState.value,
        });
        setFirstName({
          error: false,
          message: "",
          hide: true,
          value: firstNameState.value,
          fillColor: firstNameState.fillColor,
        });
        setLastName({
          error: false,
          message: "",
          hide: true,
          value: lastNameState.value,
          fillColor: lastNameState.fillColor,
        });
        break;
    }
    persistData(selectedOption.value, "identificationType");
    persistData(selectedOption.label, "identificationTypeName");
    validateData(idNumberState.value, "identificationNumber");
  };
  // compare data from local storage and set like default option
  let selected: ValueType<{ value: string; label: string }> = {
    value: "",
    label: "Selecciona una opción",
  };
  identificationTypeOptions.forEach((item) => {
    if (item.value == initialIdentificationType) {
      selected = item;
    }
  });

  const [getSelected, setSelected] = useState(selected);
  useEffect(() => {
    if (getSelected && getSelected.label != "Selecciona una opción") {
      handleChange(getSelected, false);
    }
  }, [initialIdentificationType]);

  useEffect(() => {
    const ShippingInitial = JSON.parse(
      window.localStorage.getItem("address") || "{}"
    );

    if (useShipping) {
      let selected: ValueType<{ value: string; label: string }> = {
        value: ShippingInitial.identificationType,
        label: "Selecciona una opción",
      };
      identificationTypeOptions.forEach((item) => {
        if (item.value == ShippingInitial.identificationType) {
          selected = item;
        }
      });

      setSelected(selected);
      if (selected && selected.label != "Selecciona una opción") {
        handleChange(selected, false);
      }

      setFirstName({
        error: false,
        message: "",
        hide: false,
        value: ShippingInitial.firstName ? ShippingInitial.firstName : "",
      });
      setLastName({
        error: false,
        message: "",
        hide: false,
        value: ShippingInitial.lastName ? ShippingInitial.lastName : "",
      });
      setEmail({
        error: false,
        message: "",
        hide: false,
        value: ShippingInitial.email ? ShippingInitial.email : "",
      });
      setMobile({
        error: false,
        message: "",
        hide: false,
        value: ShippingInitial.phone ? ShippingInitial.phone : "",
      });
      setIdNumber({
        error: false,
        message: "",
        value: ShippingInitial.identificationNumber
          ? ShippingInitial.identificationNumber
          : "",
      });
      setAddress({
        error: false,
        message: "",
        hide: false,
        value: ShippingInitial.reference ? ShippingInitial.reference : "",
      });
    }
  }, [useShipping]);

  useEffect(() => {
    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok);
      });
  });

  const validateAllInputs = () => {
    if (identificationState.value.length <= 0) {
      setIdentificationState({
        ...identificationState,
        error: true,
        style: errorStyle,
      });
    }
    if (identificationState.value == "CI" || identificationState.value == "") {
      validateData(idNumberState.value, "identificationNumber");
      validateData(firstNameState.value, "firstName");
      validateData(lastNameState.value, "lastName");
      validateData(mobileState.value, "mobile");
      validateData(emailState.value, "email");
      validateData(addressState.value, "address");
    } else if (identificationState.value == "RUC") {
      validateData(idNumberState.value, "identificationNumber");
      validateData(businessNameState.value, "businessName");
      validateData(mobileState.value, "mobile");
      validateData(emailState.value, "email");
      validateData(addressState.value, "address");
    }
    return true;
  };

  const handleValidateForm = async () => {
    await validateAllInputs();
    if (identificationState.type == "RUC") {
      if (!mobileState.value || mobileState.error) {
        if (mobileState.value.length == 0) {
          forceShowNotification &&
            forceShowNotification({
              type: "alert",
              message: "Debe ingresar un número de teléfono.",
            });
          return false;
        }
      }

      if (
        !identificationState.type ||
        !idNumberState.value ||
        !businessNameState.value ||
        !emailState.value ||
        !addressState.value ||
        idNumberState.error ||
        identificationState.error ||
        businessNameState.error ||
        emailState.error ||
        addressState.error
      ) {
        forceShowNotification &&
          forceShowNotification({
            type: "alert",
            message: "Los campos con (*) son requeridos",
          });
        return false;
      }
    } else {
      if (!mobileState.value || mobileState.error) {
        if (mobileState.value.length == 0) {
          forceShowNotification &&
            forceShowNotification({
              type: "alert",
              message: "Debe ingresar un número de teléfono.",
            });
          return false;
        }
        if (mobileState.error) return false;
      }
      if (
        !idNumberState.value ||
        !firstNameState.value ||
        !lastNameState.value ||
        !emailState.value ||
        !addressState.value ||
        idNumberState.error ||
        identificationState.error ||
        firstNameState.error ||
        lastNameState.error ||
        emailState.error ||
        addressState.error
      ) {
        forceShowNotification &&
          forceShowNotification({
            type: "alert",
            message: "Los campos con (*) son requeridos",
          });
        return false;
      }
    }

    const customer: Customer = {
      firstName: identificationState.type == "RUC" ? "" : firstNameState.value,
      lastName: identificationState.type == "RUC" ? "" : lastNameState.value,
      businessName:
        identificationState.type == "RUC" ? businessNameState.value : "",
      email: emailState.value,
      mobile: mobileState.value,
      address: addressState.value,
      identificationNumber: idNumberState.value,
      identificationType: identificationState.value,
      identificationTypeName: identificationState.type,
    };


    window.localStorage.setItem("customer", JSON.stringify(customer));
    history.push("/payment");

    return true;
  };

  return loaded ? (
    <>
      <Checkout>
        <Grid.Row>
          {isMobile && (
            <Grid.Column>
              <ContainerElements>
                <Collapsible
                  trigger={<TitleSummaryResponsive iconName="down-open" />}
                  triggerWhenOpen={
                    <TitleSummaryResponsive iconName="up-open" />
                  }
                >
                  <ProductSummary />
                </Collapsible>
              </ContainerElements>
            </Grid.Column>
          )}
          {isMobile && (
            <Grid.Column>
              <ContainerElements>
                <DateDelivery />
              </ContainerElements>
            </Grid.Column>
          )}
          {isMobile && <Grid.Column />}

          <Grid.Column md={isMobile || isIpad ? 12 : 9}>
            <div className="animated fadeInLeft">
              <Grid.Row>
                <Grid.Column md={12}>
                  <h3>Datos de Cliente</h3>
                </Grid.Column>
              </Grid.Row>
              <ContainerForm backgroundcolor={secondary}>
                <Grid.Row>
                  <Grid.Column lg={12} md={12} sm={12}>
                    <TermsContainer
                      onClick={() => setUseShipping(!useShipping)}
                    >
                      <Checkbox>
                        {useShipping == true ? (
                          <Icon
                            color="black"
                            size="11px"
                            name="ok"
                            className="kronos-icon"
                          />
                        ) : (
                          <div />
                        )}
                      </Checkbox>
                      <label>Usar mismos datos de contacto de entrega.</label>
                    </TermsContainer>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column lg={6} md={6} sm={12}>
                    <FlexCol>
                      <span>* Tipo de Identificación</span>
                      <Select
                        options={identificationTypeOptions}
                        onChange={(e: any) => handleChange(e, true)}
                        styles={identificationState.style}
                        value={getSelected}
                      />
                      {identificationState.error ? (
                        <Error>Campo obligatorio, no puede estar vacio</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>
                  </Grid.Column>
                  <Grid.Column lg={6} md={6} sm={12}>
                    <FlexCol>
                      <Input
                        onBlur={(e: any) => {
                          persistData(e.target.value, "identificationNumber");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "identificationNumber");
                        }}
                        id="identificationNumber"
                        label="Identificación"
                        type="text"
                        required
                        disabled={identificationState.disable}
                        error={idNumberState.error}
                        value={idNumberState.value}
                      />
                      {idNumberState.error ? (
                        <Error>{idNumberState.message}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column lg={6} md={6} sm={12}>
                    <FlexCol
                      className={firstNameState.hide ? "hidden-input" : ""}
                    >
                      <Input
                        onBlur={(e: any) => {
                          persistData(e.target.value, "firstName");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "firstName");
                        }}
                        id="firstName"
                        type="text"
                        label="Nombre"
                        required
                        error={firstNameState.error}
                        value={firstNameState.value}
                        backgroundColor={firstNameState.fillColor}
                      />
                      {firstNameState.error ? (
                        <Error>{firstNameState.message}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>

                    <FlexCol
                      className={businessNameState.hide ? "hidden-input" : ""}
                    >
                      <Input
                        onBlur={(e: any) => {
                          persistData(e.target.value, "businessName");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "businessName");
                        }}
                        id="businessName"
                        type="text"
                        label="Razón Social"
                        required
                        error={businessNameState.error}
                        value={businessNameState.value}
                      />
                      {businessNameState.error ? (
                        <Error>{businessNameState.message}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>
                  </Grid.Column>
                  <Grid.Column
                    lg={6}
                    md={6}
                    sm={12}
                    className={lastNameState.hide ? "hidden-input" : ""}
                  >
                    <FlexCol>
                      <Input
                        onBlur={(e: any) => {
                          persistData(e.target.value, "lastName");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "lastName");
                        }}
                        id="lastName"
                        type="text"
                        label="Apellido"
                        required
                        error={lastNameState.error}
                        value={lastNameState.value}
                        backgroundColor={lastNameState.fillColor}
                      />
                      {lastNameState.error ? (
                        <Error>{lastNameState.message}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>
                  </Grid.Column>
                  <Grid.Column lg={6} md={6} sm={12}>
                    <FlexCol>
                      <Input
                        onBlur={(e: any) => {
                          persistData(e.target.value, "email");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "email");
                        }}
                        id="email"
                        type="text"
                        label="Correo Electrónico"
                        required
                        maxLength={60}
                        error={emailState.error}
                        value={emailState.value}
                        backgroundColor={emailState.fillColor}
                      />
                      {emailState.error ? (
                        <Error>{emailState.message}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>
                  </Grid.Column>
                  <Grid.Column lg={6} md={6} sm={12}>
                    <FlexCol>
                      <Input
                        onBlur={(e: any) => {
                          persistData(e.target.value, "mobile");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "mobile");
                        }}
                        id="mobile"
                        type="text"
                        label="Teléfono Celular"
                        required
                        error={mobileState.error}
                        value={mobileState.value}
                        backgroundColor={mobileState.fillColor}
                      />
                      {mobileState.error ? (
                        <Error>{mobileState.message}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </FlexCol>
                  </Grid.Column>

                  <Grid.Column lg={6} md={6} sm={12}>
                    <FlexCol>
                      <Textarea
                        onBlur={(e: any) => {
                          persistData(e.target.value, "address");
                        }}
                        onChange={(e: any) => {
                          validateData(e.target.value, "address");
                        }}
                        onKeyUp={(e: any) => {
                          setCount(addressState.value.length);
                        }}
                        rows={3}
                        maxLength={50}
                        borderColor="#2684FF"
                        label="Dirección de Facturación"
                        required
                        id="address"
                        error={addressState.error}
                        value={addressState.value}
                      />
                      <TextAreaElements>
                        <Element>
                          {addressState.error ? (
                            <Error>{addressState.message}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Element>
                        <CountContainer>{count}/50</CountContainer>
                      </TextAreaElements>
                    </FlexCol>
                  </Grid.Column>
                </Grid.Row>
              </ContainerForm>
            </div>
          </Grid.Column>
          {!isMobile && (
            <Grid.Column md={isIpad ? 6 : 3}>
              <TitleSummary />
              <ContainerElements>
                <ProductSummary />
              </ContainerElements>
              {!isIpad && (
                <>
                  <ContainerElements>
                    <DateDelivery />
                  </ContainerElements>
                  <ContainerElements></ContainerElements>
                  <ContainerElements>
                    <Button
                      backgroundColor={primary}
                      color={secondary}
                      onClick={handleValidateForm}
                      id={"MKTPL_FACT_NEXT"}
                    >
                      Siguiente
                    </Button>
                  </ContainerElements>
                  <ContainerElements>
                    <NavButton
                      to="/shipping"
                      backgroundColor="#f7f7f7"
                      color={primary}
                      bordercolor={primary}
                      third={"#000000"}
                      onClick={() => {
                        onSetLoaded && onSetLoaded(false);
                      }}
                    >
                      Atrás
                    </NavButton>
                  </ContainerElements>
                </>
              )}
            </Grid.Column>
          )}
          {!isMobile && isIpad && (
            <Grid.Column md={6} style={{ margin: "3.5rem 0 0" }}>
              <ContainerElements>
                <DateDelivery />
              </ContainerElements>

              <ContainerElements>
                <Button
                  backgroundColor={primary}
                  color={secondary}
                  onClick={handleValidateForm}
                  id={"MKTPL_FACT_NEXT"}
                >
                  Siguiente
                </Button>
              </ContainerElements>
              <ContainerElements>
                <NavButton
                  to="/cart"
                  backgroundColor="#f7f7f7"
                  color={primary}
                  bordercolor={primary}
                  third={"#000000"}
                  onClick={() => {
                    onSetLoaded && onSetLoaded(false);
                  }}
                >
                  Atrás
                </NavButton>
              </ContainerElements>
            </Grid.Column>
          )}
          {isMobile && (
            <Grid.Column>
              <ContainerElements>
                <Button
                  backgroundColor={primary}
                  color={secondary}
                  onClick={handleValidateForm}
                  id={"MKTPL_FACT_NEXT"}
                >
                  Siguiente
                </Button>
              </ContainerElements>
              <ContainerElements>
                <NavButton
                  to="/shipping"
                  backgroundColor="#f7f7f7"
                  color={primary}
                  bordercolor={primary}
                  third={"#000000"}
                  onClick={() => {
                    onSetLoaded && onSetLoaded(false);
                  }}
                >
                  Atrás
                </NavButton>
              </ContainerElements>
            </Grid.Column>
          )}
        </Grid.Row>
      </Checkout>
    </>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
    </Checkout>
  );
}

const AddTracklWithMutation = graphql(ADD_TRACKING_MUTATION)(
  withRouter(Billing)
);
export default AddTracklWithMutation;
