import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Items, TypeItems } from "../../helpers/itemsMobile";

import CartContext from "../../context/CartContext";
import { ContainerItems, Box, Item } from "./style";
import Icon from "../Icon";

function MenuItems() {
  const { items } = useContext(CartContext);
  return (
    <ContainerItems>
      <Box>
        {Items.map(({ pathname, name, order }: TypeItems) => {
          if (name == "Carrito de compras" && items.length == 0) {
            pathname = "empty-cart";
          }
          return (
            <Item key={pathname}>
              <Link to={pathname}>
                <span>{name}</span>
                <Icon
                  color="black"
                  size="20px"
                  name="right-open"
                  className="kronos-icon"
                />
              </Link>
            </Item>
          );
        })}
      </Box>
    </ContainerItems>
  );
}

export default withRouter(MenuItems);
