import styled from "styled-components";
import { StickyContainer } from "react-sticky";
import Sidebar from "react-sidebar";
import { Container } from "../../components/Grid";
import sizes from "../../styles/media";
import { ToastContainer as TC } from "react-toastify";
interface Props {
  backgroundcolor?: string;
  showBanner?: boolean;
  isSticky?: boolean;
}

export const CustomStickyContainer = styled(StickyContainer)`
  flex: auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const FooterWrapper = styled.footer`
  ${(props: Props) => {
    let styles = `
        box-shadow: 0 0 4px 0 rgba(117, 120, 123, 0.5);
        background-color: ${props.backgroundcolor};
        padding: 1rem 0;
    `;

    return styles;
  }};
`;

export const HeaderWrapper = styled.header`
  background-color: ${(props: Props) => props.backgroundcolor};
  box-shadow: 0 2px 4px 0 rgba(88, 88, 88, 0.3);
  padding: 0.5rem 0;
  z-index: 3;
  width: 100%;
  position: fixed;
  /* margin-bottom: ${(props: Props) =>
    props.showBanner === true ? "3.5rem" : 0}; */

  @media ${sizes.md} {
    padding: 1rem 0;
    position: relative;
  }
`;

export const Main = styled.div<Props>`
  display: block;
  flex: auto;
  padding: 0;
  background-color: ${(props) => props.backgroundcolor};
  margin-top: 4rem;
  @media ${sizes.md} {
    margin-top: 0;
  }
`;

export const StyledSideBar = styled(Sidebar)`
  display: flex;
  flex: 1;
`;

export const StyledContainer = styled(Container)`
  display: flex;
`;

export const StyledAnimationLarge = styled.div`
  display: none;
  @media ${sizes.xl} {
    width: 1115px !important;
    max-width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    z-index: 5;
    display: ${(props: Props) =>
      props.isSticky === true ? "none !important" : "block"};
  }
`;

export const StyledAnimationMedium = styled.div`
  display: none;
  @media ${sizes.md} {
    width: 766px !important;
    max-width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    z-index: 5;
    display: ${(props: Props) =>
      props.isSticky === true ? "none !important" : "block"};
  }

  @media ${sizes.xl} {
    display: none;
  }
`;

export const StyledAnimationMobile = styled.div`
  width: 335px !important;
  max-width: 100% !important;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 2rem;
  overflow: hidden;
  z-index: 5;
  display: ${(props: Props) =>
    props.isSticky === true ? "none !important" : "block"};

  @media ${sizes.md} {
    display: none;
  }
`;

const className = "dark-toast";
const toastClassName = "toast-container";

export const ToastContainer = styled(TC).attrs<Props>({
  className,
  toastClassName,
})`
  .${toastClassName} {
    height: 60px;
    border-radius: 0.7em;
    padding: 10px;
    font-size: 1.2em;

    &.Toastify__toast {
      min-height: 60px;
      margin: 0.5rem auto;
      width: 85%;
      @media ${sizes.md} {
        width: 100%;
      }
    }
  }
`;
