import styled from "styled-components";
import sizes from "../../styles/media";
import { Column } from "../../components/Grid";
import { Link } from "react-router-dom";
import Icon from "../Icon";

interface ItemProp {
  selected?: boolean;
}

interface CouponProps {
  hasCoupon?: boolean;
}

export const Img = styled.img`
  width: auto;
`;

export const ImgCart = styled.img`
  margin: 0 0.5rem;
  width: 2rem;
`;
export const Burger = styled.img`
  width: 1.5rem;
`;

export const TextCart = styled.span`
  padding-top: 0.25rem;
  color: #f7f7f7;
  display: none;
  @media ${sizes.md} {
    display: inline-block;
  }
`;

export const Cart = styled.div`
  display: flex;
  align-items: baseline;
  align-self: center;
  position: relative;
`;

export const Oval = styled.span`
  font-family: Arial;
  color: #ffffff;
  background: red;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: -0.6rem;
  right: 0rem;
  width: 18px;
  height: 18px;
  padding-top: 3px;
`;

export const BurguerContainer = styled(Column)`
  display: flex;
  align-items: center;
  @media ${sizes.md} {
    display: none;
  }
`;

export const LogoContainer = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;

  @media ${sizes.sm} {
    justify-content: left;
    flex-wrap: nowrap;
  }
`;

export const StyledLogoLinkContainer = styled(Link)`
  width: 100%;
  max-width: 90%;
  margin: 0.25rem;
  display: flex;
  @media ${sizes.sm} {
    max-width: 100%;
  }
`;

export const ActionLinksContainer = styled(Column)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .separator {
    margin: auto 0;
    padding: 0.25rem 0.8rem 0;

    height: 1.2rem;
  }
`;

export const MenuLink = styled.div`
  display: none;
  color: #1428a0;
  font-weight: bold;

  @media ${sizes.md} {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    a {
      color: #132496;
    }
  }
`;

export const CartContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

export const HeaderLinks = styled(Link)`
  display: none;
  transition: none;

  @media ${sizes.md} {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
  }
  &.no-border {
    span {
      border-right: 0px solid white;
    }
  }
  span {
    border-right: 0.11rem solid white;
    padding: 0.1rem 0.8rem 0.1rem;
    position: relative;
    line-height: 0.9;
    &:before {
      content: "";
      border-bottom: solid 2px white;
      position: absolute;
      width: 80%;
      bottom: -2px;
      left: 0;
      right: 0;
      margin: auto;
      visibility: ${(props: ItemProp) =>
        props.selected === true ? "visible" : "hidden"};
      -webkit-transform: ${(props: ItemProp) =>
        props.selected === true ? "scaleX(1)" : "scaleX(0)"};
      transform: ${(props: ItemProp) =>
        props.selected === true ? "scaleX(1)" : "scaleX(0)"};
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
  }
  &:hover {
    background: transparent;
    span {
      &:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
`;

export const Space = styled.span`
  margin-left: 10px;
`;

export const StyledIcon = styled(Icon)`
  position: relative;
  right: -0.8rem;
  @media ${sizes.md} {
    right: 0;
  }
`;

export const CustomColumn = styled(Column)`
  margin: auto;
`;

export const BellContainer = styled.div`
  display: ${(props: CouponProps) => (props.hasCoupon ? "flex" : "none")};
  align-items: center;

  cursor: ${(props: CouponProps) => (props.hasCoupon ? "pointer" : "")};
  i {
    &:before {
      margin: 0;
    }
    animation: mymove 0.8s infinite;
  }
  &:hover {
    i {
      color: #c59d5f;
    }
  }

  @keyframes mymove {
    from {
      left: -2px;
    }
    to {
      left: 2px;
    }
  }
`;
