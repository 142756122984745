import React from "react";
import Layout from "../Layout";
import Breadcrumb from "../Breadcrumb";
import { Row, Column } from "../Grid";

import Stepper from "./components/Stepper";
// import ProductSummary from "./components/ProductSummary";
// import TitleSummary from "./components/TitleSummary";
// import Collapsible from 'react-collapsible';

import { Spacer } from "./style";

type Props = {
  children: React.ReactNode;
  showRightSection?: boolean;
  placeToPaySection?: boolean;
};

function Checkout({ children }: Props) {
  return (
    <Layout>
      <Spacer />
      <Row>
        <Column>
          <Breadcrumb
            links={[
              { label: "Inicio", to: "/" },
              {
                label: "Checkout",
              },
            ]}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Stepper />
        </Column>
      </Row>
      <Spacer />
      <Row>
        <Column>{children}</Column>
      </Row>
    </Layout>
  );
}

export default Checkout;
