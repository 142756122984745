import React, { useContext, useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import Variation from '../../model/Variation'
import formatNumber from "../../helpers/formatNumber";
import Icon from "../Icon";
import { Button } from "../Buttons";
//import LazyImage from "../LazyImage";
//import { NavButton } from "../Buttons";
import { Link } from "react-router-dom";

import Collapsible from "react-collapsible";
import ThemeContext from "../../context/ThemeContext";
import CartContext from "../../context/CartContext";
//import { GET_CITY_QUERY } from "../../views/Product/query";
import { withApollo } from "react-apollo";
import Select from "react-select";
//import calculateDiscount from "../../helpers/calculateDiscount";
import {
  ProductContainer,
  ImgContainer,
  ButtonContainer,
  ProductInfoContainer,
  ProductPriceContainer,
  CustomTitle,
  DetailContainer,
  ItemContainer,
  Title,
  QuantityContainer,
  QuantityButton,
  Quantity,
  Price,
  PriceInfo,
  Detail,
  FrequencyInfoContainer,
  ContainerAnswer,
  ContainerTitle,
  SelectWrapper,
} from "./style";
import { DiscountContainer } from "../Global/style";
import Product from "../../model/Product";
import Variation from "../../model/Variation";
import Assets from "../../model/Assets";
import defaultImg from "../../assets/images/product-default.jpg";

interface Props extends RouteComponentProps<any> {
  product: Product;
  selectedIndex: number;
  index: number;
  setSelected: any;
  client?: any;
}

const staticAddress = {
  province: "",
  provinceName: "",
  city: "",
  cityName: "",
};
const customStyle = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "4px",
    fontWeight: "900",
    border: "solid 1px #242424",
    cursor: "pointer",
    fontSize: "14px",
    color: "#242424",
    outline: "none",
    padding: "0.1rem 0",
  }),
  container: (provided: any) => ({
    ...provided,
    width: "100%",
    color: "#242424",
    outline: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? "#f9f4ee" : "white",
    cursor: "pointer",
    padding: "0.85rem 1rem",
    color: "#242424",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "#242424",
  }),
  menuList: (styles: any) => {
    return {
      ...styles,
      maxHeight: 136,
    };
  },
  singleValue: (provided: any) => {
    return {
      ...provided,
      color: "#242424",
    };
  },
};

export const ProductCard = ({
  history,
  product,
  selectedIndex,
  index,
  setSelected,
  client,
}: Props) => {
  const [count, setCount] = useState(1);
  const [selectedVariation, setselectedVariation] = useState<Variation>();
  const [assetsArray, setAssetsArray] = useState<Assets[] | []>([]);
  if (assetsArray.length > 1000) {
    console.log(assetsArray.length);
  }
  const {
    changeDeliveryInfo,
    onDelAllItems,
    onAddToCart,
    forceShowNotification,
  } = useContext(CartContext);
  const { secondary } = useContext(ThemeContext);
  const [optionSelect, setOptionSelect] = useState({
    label: selectedVariation ? selectedVariation.options[0].name : "Frecuencia",
    value: selectedVariation ? selectedVariation.options[0].id : "",
  });

  const { name } = product;
  const handleSelect = () => {
    setSelected(index);
  };

  const handleSelectedOption = (option: any) => {
    setOptionSelect(option);
    if (product && product.variations) {
      let variation = product.variations.filter((itemV: any) => {
        return itemV.options[0].id == option.value;
      });
      setselectedVariation(variation[0]);
    }
  };
  const selectVariation = (variation: Variation) => {
    setselectedVariation(variation);
    setOptionSelect({
      label: variation.options[0].name,
      value: variation.options[0].id,
    });
    let arrAssets: Assets[] = [];
    if (product.assets) {
      arrAssets = arrAssets.concat(product.assets);
    }
    setAssetsArray(arrAssets);
  };

  /* const getCityByName = async (cityName: string) => {
    const { data } = await client.query({
      query: GET_CITY_QUERY,
      variables: {
        filters: { s: cityName ? cityName : "" },
      },
    });

    if (data) {
      const response = data.cityByName;
      return response;
    }
  }; */

  const addtoCart = async () => {
    if (selectedVariation) {
      const {
        inventory: { localStock, storageStock },
      } = selectedVariation;
      let no_stock = localStock + storageStock == 0 ? true : false;
      if (no_stock) {
        forceShowNotification &&
          forceShowNotification({
            type: "fail",
            message: "Producto fuera de stock",
          });
      } else {
        const addVariation = selectedVariation;
        addVariation["product"] = product;
        onDelAllItems && (await onDelAllItems());
        onAddToCart && (await onAddToCart(addVariation, count));
        if (
          product.supplier &&
          product.supplier.metaData &&
          product.supplier.metaData.entrega
        ) {
          changeDeliveryInfo &&
            changeDeliveryInfo(product.supplier.metaData.entrega);
        } else {
          changeDeliveryInfo &&
            changeDeliveryInfo(
              "Recuerda que las entregas se realizarán los días viernes de cada semana. El valor de la suscripción se verá reflejado en tu tarjeta de crédito Grande Table"
            );
        }

        /* Update City and State addresses */
        /* const cityResponse: any =
          getCityByName &&
          (await getCityByName(
            product.supplier ? "2fbbf51a-0dbc-4bbe-b717-70cb44ca1d40" : ""
          )); */

        //if (cityResponse) {
        staticAddress.city = "2fbbf51a-0dbc-4bbe-b717-70cb44ca1d40";
        staticAddress.cityName = "QUITO";
        staticAddress.province = "9b5d2403-cacc-4f88-90f1-cd1d6cb8f9f2";
        staticAddress.provinceName = "PICHINCHA";
        //}
        /**********************************/

        window.localStorage.setItem("address", JSON.stringify(staticAddress));

        /*  forceShowNotification &&
          forceShowNotification({
            type: "ok",
            message: name + " se ha añadido al carrito.",
          }); */
        setCount(1);
        await history.push("/shipping");
      }
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: "fail",
          message: "Producto no disponible.",
        });
    }
  };

  const renderProductImages = () => {
    if (product.assets) {
      setAssetsArray(product.assets);
    }
  };

  const quantityComponent = () => {
    if (selectedVariation) {
      let {
        inventory: { localStock, storageStock },
      } = selectedVariation;
      let no_stock = localStock + storageStock == 0 ? true : false;
      return (
        <>
          <DetailContainer>
            <ItemContainer>
              <div className="quantity-title">Cantidad de cajas: </div>
              <QuantityContainer>
                <QuantityButton
                  backgroundcolor={"#c59d5f"}
                  fontcolor={secondary}
                  onClick={() => count > 1 && setCount(count - 1)}
                >
                  <Icon
                    color={secondary}
                    name="minus"
                    className="kronos-icon"
                  />
                </QuantityButton>
                <Quantity backgroundcolor={"#c59d5f"}>
                  {no_stock ? 0 : count}
                </Quantity>
                <QuantityButton
                  backgroundcolor={"#c59d5f"}
                  fontcolor={secondary}
                  onClick={() =>
                    localStock + storageStock > count &&
                    10 > count &&
                    setCount(count + 1)
                  }
                >
                  <Icon color={secondary} name="plus" className="kronos-icon" />
                </QuantityButton>
              </QuantityContainer>
            </ItemContainer>
            <SelectWrapper>
              <div className="quantity-title">Frecuencia de entrega: </div>
              {product.features &&
                product.features.map((feature) => {
                  const optionsFeature: any[] = [];
                  return (
                    <>
                      {feature.options && feature.options.length > 1 && (
                        <>
                          {feature.options.map((option) => {
                            optionsFeature.push({
                              label: option.name,
                              value: option.id,
                            });
                          })}
                          <Select
                            styles={customStyle}
                            autoFocus={false}
                            options={optionsFeature}
                            onChange={async (optionSelected: any) => {
                              handleSelectedOption(optionSelected);
                            }}
                            value={optionSelect}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </>
                      )}
                    </>
                  );
                })}
            </SelectWrapper>
          </DetailContainer>
        </>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (product) {
      if (product.variations) {
        const filteredVariations = product.variations.filter((variation) => {
          return variation.status == "active";
        });
        if (filteredVariations.length > 0) {
          selectVariation(filteredVariations[0]);
        } else {
          renderProductImages();
        }
      }
    }
  }, []);
  return (
    <ProductContainer
      onClick={() => handleSelect()}
      className={
        selectedIndex >= 0
          ? selectedIndex === index
            ? "active"
            : "inactive"
          : ""
      }
    >
      <div className="content">
        <ImgContainer>
          <img
            src={
              product && product.assets && product.assets.length > 0
                ? product.assets[0].url
                : defaultImg
            }
            alt="Producto"
          />
        </ImgContainer>
        <Detail>
          <ProductInfoContainer>
            <CustomTitle>{name}</CustomTitle>
          </ProductInfoContainer>
          <ProductPriceContainer>
            <PriceInfo>Precio del producto</PriceInfo>
            <DiscountContainer>
              <Price>
                ${" "}
                {formatNumber(
                  selectedVariation
                    ? selectedVariation.price * count
                    : product.minPrice * count
                )}
              </Price>
            </DiscountContainer>
          </ProductPriceContainer>
          <FrequencyInfoContainer>{quantityComponent()}</FrequencyInfoContainer>
          <ButtonContainer>
            <Button
              onClick={addtoCart}
              backgroundColor={"#c59d5f"}
              color={secondary}
              bordercolor={"#c59d5f"}
            >
              SUSCRIBIRME
            </Button>
          </ButtonContainer>
          <ProductInfoContainer>
            <Collapsible
              trigger={
                <ContainerTitle>
                  <Title>Descripción del producto</Title>
                  <>
                    <Icon
                      color="#ffffff"
                      size="14px"
                      name="down-open"
                      className="kronos-icon"
                    />
                  </>
                </ContainerTitle>
              }
              triggerWhenOpen={
                <ContainerTitle>
                  <Title>Descripción del producto</Title>
                  <>
                    <Icon
                      color="#ffffff"
                      size="14px"
                      name="up-open"
                      className="kronos-icon"
                    />
                  </>
                </ContainerTitle>
              }
            >
              <ContainerAnswer>
                <span
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </ContainerAnswer>
            </Collapsible>
          </ProductInfoContainer>

          <ProductInfoContainer>
            <Collapsible
              trigger={
                <ContainerTitle>
                  <Title>Información de entrega</Title>
                  <>
                    <Icon
                      color="#ffffff"
                      size="14px"
                      name="down-open"
                      className="kronos-icon"
                    />
                  </>
                </ContainerTitle>
              }
              triggerWhenOpen={
                <ContainerTitle>
                  <Title>Información de entrega</Title>
                  <>
                    <Icon
                      color="#ffffff"
                      size="14px"
                      name="up-open"
                      className="kronos-icon"
                    />
                  </>
                </ContainerTitle>
              }
            >
              <ContainerAnswer>
                Las entregas se realizarán el día Viernes de cada semana. Debes
                realizar tu pedido con 48 horas de anticipación.
              </ContainerAnswer>
            </Collapsible>
          </ProductInfoContainer>

          <ProductInfoContainer>
            <Collapsible
              trigger={
                <ContainerTitle>
                  <Title>Información de suscripción</Title>
                  <>
                    <Icon
                      color="#ffffff"
                      size="14px"
                      name="down-open"
                      className="kronos-icon"
                    />
                  </>
                </ContainerTitle>
              }
              triggerWhenOpen={
                <ContainerTitle>
                  <Title>Información de suscripción</Title>
                  <>
                    <Icon
                      color="#ffffff"
                      size="14px"
                      name="up-open"
                      className="kronos-icon"
                    />
                  </>
                </ContainerTitle>
              }
            >
              <ContainerAnswer>
                Recibe tus tomates en casa todos los meses según la frecuencia
                seleccionada. El costo mensual lo verás reflejado en tu tarjeta
                Grande Table. <br /> <br /> Para más información puedes revisar
                las <Link to="/faqs">Preguntas Frecuentes.</Link>
              </ContainerAnswer>
            </Collapsible>
          </ProductInfoContainer>
        </Detail>
      </div>
      {/* <ButtonContainer
        onClick={() => redirectProduct(slug)}
        className="MKTPL_HOME_SELEC"
        data-product={name}
      >
        <NavButton
          to={`/product/${slug}`}
          backgroundColor={secondary}
          color={primary}
          bordercolor={primary}
          third={"#c59d5f"}
        >
          Ver más
        </NavButton>
      </ButtonContainer> */}
    </ProductContainer>
  );
};

export default withRouter(withApollo(ProductCard));
