import gql from "graphql-tag";

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $filters: ProductsFilters!
    $options: ProductsOptions!
  ) {
    products(filters: $filters, options: $options) {
      nodes {
        id
        name
        slug
        description
        assets {
          url
          order
        }
        features {
          id
          name
          options {
            id
            name
          }
        }
        supplier {
          id
          city
          metaData {
            entrega
          }
        }
        categories {
          slug
          name
          id
        }
        variations {
          id
          price
          reference
          status
          options {
            id
            name
            feature {
              id
              name
            }
          }
          inventory {
            id
            localStock
            storageStock
          }
        }
        minPrice
        maxRegularPrice
      }
    }
  }
`;

const GET_CITIES = gql`
  query getCities($filters: CategoriesFilters!, $options: CategoriesOptions!) {
    categories(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`;

export { GET_PRODUCTS_QUERY, GET_CITIES };
