import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import Modal from 'react-modal'
interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const ProductListContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  background: #ffffff;
  margin-top: 1rem;
`

export const TitleContainer = styled.div`
  display: flex;
  color: #ffffff;
  background: #000000;
  text-align: left;
`

export const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1rem;
`

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 0.2rem;
  height: auto;
  width: 100%;
  overflow-wrap: break-word;

  @media ${sizes.md} {
    font-size: 14px;
    margin: 0;
  }
`

export const Reference = styled.div`
  font-size: 11px;
  margin: 0.2rem 0;
  @media ${sizes.lg} {
    font-size: 13px;
    margin: 0.2rem 0;
  }
`

export const IconTitle = styled.div`
  font-weight: bold;
  margin: 0 4px;
  @media ${sizes.xl} {
    margin: 0 4px 0 0;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 1rem;
`

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  i {
    margin-right: 4px;
  }
`

export const ItemStock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 1rem 1rem;
  @media ${sizes.md} {
    margin-left: 1rem;
  }
  @media ${sizes.xl} {
    padding: 0;
    margin-left: 0;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  border: solid 1px #d9d9d9;
  width: 120px;
  flex-direction: column;
  justify-content: center;
`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

export const Stock = styled.span`
  color: red;
`

export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      padding-top: 4px;
      `
  }};
`

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    color: ${props.fontcolor};
    border: solid 1px ${props.backgroundcolor};
    outline: none;
    cursor: pointer;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    height: 100%;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
  width: 75px;

  @media ${sizes.xs} {
    height: auto;
    width: auto;
  }

  @media ${sizes.sm} {
    height: 28px;
  }
`

export const Line = styled.div`
  margin: auto;
  border-bottom: solid 1px #d9d9d9;
  width: 93%;
`

export const ActionsCartContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
`

export const Clean = styled.div`
  background: none;
  cursor: pointer;
  color: #c59d5f;
  letter-spacing: 1px;
  text-decoration: underline;
  font-size: 12px;
`

export const Continue = styled(Link)`
  color: #c59d5f;
  font-size: 0.929rem;
  letter-spacing: 1px;
  font-size: 12px;
  text-decoration: underline;
`

export const DetailPayment = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
    `
  }}
`
export const DetailPaymentMobile = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
      margin-left:auto;
      width:60%;
    `
  }}
`

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const SpanPayment = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor}
      font-weight: bold;
    `
  }}
`

export const SpanShipping = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor}
      font-weight: bold;
      margin-bottom:1rem;
      float:right;
      padding-right: 1rem;
      margin-top: 1rem;
      font-size: 12px;
      @media ${sizes.md} {
        font-size: 14px;
      }
    `
  }}
`

export const TotalContainer = styled.div`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    padding: 1rem 16px 4px 16px;
  `
  }}
`

export const TotalContainerMobile = styled.div`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    padding: 0rem 16px 4px 16px;
    margin-left:auto;
    width:60%;
  `
  }}
`

export const AmountsContainer = styled.div`
  justify-content: right;
  margin-left: auto;
  width: 25%;
`

export const AmountsContainerMobile = styled.div`
  justify-content: right;
  margin-left: auto;
  margin-top: 0.5rem;
  width: 100%;
`

export const ButtonContainer = styled.div`
  margin-left: auto;
  width: 100%;
  padding: 20px 16px 4px 16px;
`
export const ButtonContainerMobile = styled.div`
  margin-left: auto;
  padding: 1rem 16px 4px 16px;
  width: 100%;
`
export const ButtonMobile = styled.div`
  margin-left: auto;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
`

export const ListContainerMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  position: relative;
  width: 100%;
`

export const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  background: #ffffff;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`

export const RowContainer = styled.div`
  display: inline-flex;
  height: 100%;
`

export const PriceContainer = styled.div`
  padding-left: 0.5rem;
  font-weight: bold;
  margin: auto 0;
  font-size: 13px;
  @media ${sizes.md} {
    font-size: 14px;
  }
`

export const ContainerItem = styled.div`
  width: 100%;
`

export const LinkProduct = styled(Link)`
  color: black;
`

export const InfoPromoContainer = styled.div`
  background: #e3eed6;
  border: solid 1px #d2e4bd;
  display: flex;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 1rem 0;
`

export const CustomIcon = styled.img`
  margin-right: 1rem;
  width: 6rem;
  max-width: 100%;
`

export const InfoText = styled.h4`
  font-weight: 400;
  margin: 0;
  font-size: 0.9rem;
`

export const CouponContainer = styled.div`
  box-shadow: 0 2px 4px 0 #d9d9d9;
  background-color: #ffffff;
  padding: 20px 30px;
  color: #242424;
  margin: 20px auto;
  h2 {
    margin: 0;
    font-size: 1.1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .terms {
    font-family: LucidaGrande;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c59d5f;
    margin: 10px 15px 0;
    text-decoration: underline;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-content: flex-end;
  input {
    border: solid 1px #000000;
    background-color: #ffffff;
    margin: 5px 0 0;
    padding: 10px;
  }
  button {
    background-color: #242424;
    border: 1px solid #242424;
    color: #ffffff;
    text-transform: uppercase;
    margin: 5px 0 0;
    padding: 10px;
    font-weight: 0.8rem;
    font-family: 'Muller-Bold';
    &:disabled {
      opacity: 0.4;
    }
  }
`
export const Circle = styled.div`
  width: 68px;
  min-width: 68px;
  max-width: 68px;
  height: 68px;
  min-height: 68px;
  max-height: 68px;
  border-radius: 50%;
  background-color: #f45151;
  display: flex;
  justify-content: center;
  align-items: center;
  &.valid {
    background-color: #96d76d;
  }
  margin: auto;
`

export const ModalContent = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  color: #242424;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 30px;
  font-family: 'Roboto';
  h1 {
    font-size: 1.42rem;
    line-height: normal;
    text-align: center;
    font-family: 'Muller-Bold';
  }
  p {
    font-size: 1rem;
    line-height: 1.36;
    text-align: center;
    max-width: 400px;
  }
  ul {
    text-align: left;
    max-width: 340px;
    li {
      color: #c59d5f;
      span {
        color: #242424;
      }
    }
  }
  img {
    margin: auto;
    width: 60%;
    &.contact2 {
      width: 80%;
    }
  }
`

export const CouponModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  max-width: 600px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`

export const AmountModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  /* padding: 30px; */
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`
interface ModalProps {
  backgroundcolor?: string
  padding: string
}
export const ModalMinDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 0 0 20px 0;

    .amount-modal {
      font-family: 'Muller-Bold';
      font-weight: bold;
      color: #112340;
    }
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: #ffffff;
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }

  @media ${sizes.sm} {
    .description {
      margin: 0px 30px 30px 30px;
    }
  }
`
