export const questionItems = [
  {
    question: `¿Qué es Grande Table Farms?`,
    answer: `
        Grande Table Farms es un beneficio para socios Grande Table donde puedes suscribirte para recibir productos de temporada frescos para tu cocina, cosechados con los más altos estándares de calidad.
        `,
  },
  {
    question: `¿Cómo compro una suscripción?`,
    answer: `Para suscribirte a uno de nuestros planes, debes seguir los siguientes pasos:
    <ol>
        <li>Escoge la modalidad de tu suscripción.</li>
        <li>Ingresa los datos de facturación y entrega.</li>
        <li>Acepta el cargo recurrente a tu tarjeta Grande Table.</li>
        <li>Recibe tu comprobante de suscripción con el detalle de la entrega al correo electrónico registrado.</li>
        <li>Nos comunicaremos contigo para confirmar tu suscripción.</li>
        <li>Tu producto llegará a la dirección ingresada de acuerdo a la modalidad escogida. </li>
    </ol>`,
  },
  {
    question: `¿Qué incluye mi suscripción?`,
    answer: `En esta suscripción vas a recibir una caja de 1,2 a 1,4 kilos de tomates hidropónicos. La cantidad de cajas y frecuencia dependerán de tu selección. `,
  },
  {
    question: `¿Cuál es la modalidad de suscripción?`,
    answer: `Puedes suscribirte para recibir tus productos semanal o quincenalmente y el cobro se realizará todos los meses.`,
  },
  {
    question: `¿Cuáles son las zonas de cobertura de Grande Table Farms?`,
    answer: `Realizamos entregas en Quito y sus Valles.`,
  },
  {
    question: `¿Puedo suscribirme a un plan cualquier día de la semana?`,
    answer: `Puedes comprar tu suscrpción de Grande Table Farms 24/7. `,
  },
  {
    question: `¿Mi suscripción se renueva automáticamente?`,
    answer: `Si, el costo mensual lo verás reflejado en el estado de cuenta de tu tarjeta Grande Table. `,
  },
  {
    question: `¿Cuánto tiempo después del pago de la suscripción, recibo mis productos? `,
    answer: `Tus productos serán entregados los días viernes de cada semana. Debes realizar tu pedido con 48 horas de anticipación. `,
  },
  {
    question: `¿Los precios incluyen IVA?`,
    answer: `Si, todas nuestras suscripciones tienen un precio marcado que incluye IVA. Dentro del total del carrito podrás ver el valor total a pagar por IVA. 
    `,
  },
  {
    question: `¿Puedo comprar más de un plan de suscripción?`,
    answer: `Si deseas comprar una suscripción adicional, debes realizar el proceso de compra nuevamente.`,
  },
  {
    question: `¿El envío tiene costo?`,
    answer: `El envío de Grande Table Farms es gratuito.</a>.
    `,
  },
  {
    question: `¿Puedo diferir mi pago?`,
    answer: `No, la forma de débito será corriente a tu tarjeta Grande Table. `,
  },
  {
    question: `¿Cómo recibo la factura de mi compra?`,
    answer: `Recibirás la factura mensualmente al correo registrado en los datos de facturación.`,
  },
  {
    question: `¿Cómo me informan el estado de mis productos?`,
    answer: `Recibirás una llamada para confirmar tu suscripción y los productos te llegarán recurrentemente el día viernes todas las semanas/meses.`,
  },
  {
    question: `¿Qué pasa si no recibo mis productos?`,
    answer: `Por favor comunícate con el Servicio al Cliente de Grande Table Farms llamando al (02) 2-500-1100 o escribe a WhatsApp al 0993360413 y te informaremos del estado de tus productos. `,
  },

  {
    question: `¿Qué pasa si no estuve el día de la entrega de los productos?`,
    answer: `Si no te encontrabas en la dirección de entrega, comunícate con nosotros al (02) 2-500-1100 o escribe a WhatsApp al 0993360413 para verificar el estado de tu pedido.`,
  },
  {
    question: `¿Puedo cancelar mi suscripción después de haberla comprado?`,
    answer: `Podrás cancelar tu suscripción comunicándote con nosotros cualquier día de la semana llamando al (02) 2-500-1100. Se te enviará a tu correo la confirmación de la cancelación.`,
  },
  {
    question: `¿Puedo cambiar la fecha de entrega de mis productos?`,
    answer: `No, los productos se entregarán en un día establecido.  Para más detalles sobre el cambio de fecha de la entrega comunícate con nosotros al (02) 2-500-1100.`,
  },
  {
    question: `¿Puedo cambiar la dirección de entrega de mis productos?`,
    answer: `Si, para cambiar la dirección de entrega de tus productos comunícarte con nosotros al (02) 2-500-1100 con 48 horas de anticipación antes de tu entrega el día viernes.`,
  },
  {
    question: `¿Cómo son transportados mis productos?`,
    answer: `Llevamos los productos a la puerta de tu casa utilizando todos los protocolos de seguridad y salud establecidos.`,
  },
  {
    question: `Novedades con el pedido`,
    answer: `Comunícate con nuestros asesores al  (02) 2-500-1100 o escribe a WhatsApp al 0993360413. Estamos disponibles de lunes a domingo de 10:30 a 21:00.`,
  },
];
