import React from "react";

import {
  InstructionsContainer,
  InstructionsWrapper,
  Title,
  Step,
  StepMobile,
  Img,
  ImageContainer,
  TextContainer,
  Wrapper
  // Description,
  // TitleOffer,
  // SubTitleOffer,
  // PromoBox
} from "./style";

import { useIsMobile } from "../../helpers/resize";

import Slider from "react-slick";
import p1 from "../../assets/instructions/p1.svg";
import p2 from "../../assets/instructions/p2.svg";
import p3 from "../../assets/instructions/p3.svg";
import p4 from "../../assets/instructions/p4.svg";
import p5 from "../../assets/instructions/p5.svg";

interface Instruction {
  text: string;
  imgIndex: number;
}

interface InstructionsProps {
  scroll?: () => void;
  instructions: Array<Instruction>;
}

export const Instructions = (props: InstructionsProps) => {
  const initialMql = window.matchMedia(`(max-width: 768px)`);
  const isMobile = useIsMobile(initialMql.matches);
  const { instructions } = props;

  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
    focusOnSelect: false,
    centerMode: true,
    centerPadding: "10px"
  };

  return (
    <InstructionsContainer>
      <Title>¿Cómo funciona?</Title>
      {!isMobile && (
        <InstructionsWrapper>
          {instructions.map((instruction: Instruction, index: number) => {
            let stepImage;
            switch (instruction.imgIndex) {
              case 1:
                stepImage = p1;
                break;
              case 2:
                stepImage = p2;
                break;
              case 3:
                stepImage = p3;
                break;
              case 4:
                stepImage = p4;
                break;
              case 5:
                stepImage = p5;
                break;
            }

            return (
              <Step key={index}>
                <ImageContainer>
                  <Img src={stepImage} alt="" />
                </ImageContainer>
                <TextContainer>
                  <p dangerouslySetInnerHTML={{ __html: instruction.text }}></p>
                </TextContainer>
              </Step>
            );
          })}
        </InstructionsWrapper>
      )}

      {isMobile && (
        <Slider {...settings}>
          {instructions.map((instruction: Instruction, index: number) => {
            let stepImage;
            switch (instruction.imgIndex) {
              case 1:
                stepImage = p1;
                break;
              case 2:
                stepImage = p2;
                break;
              case 3:
                stepImage = p3;
                break;
              case 4:
                stepImage = p4;
                break;
              case 5:
                stepImage = p5;
                break;
            }
            return (
              <Wrapper>
                <StepMobile key={index}>
                  <ImageContainer>
                    <Img src={stepImage} alt="" />
                  </ImageContainer>
                  <TextContainer>
                    <p
                      dangerouslySetInnerHTML={{ __html: instruction.text }}
                    ></p>
                  </TextContainer>
                </StepMobile>
              </Wrapper>
            );
          })}
        </Slider>
      )}
    </InstructionsContainer>
  );
};

export default Instructions;
