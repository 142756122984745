import styled from "styled-components";
import sizes from "../../styles/media";
import { Container, Column } from "../../components/Grid";
import Icon from "../../components/Icon";

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;

export const Hr = styled.hr`
  border: none;
  border-bottom: solid 1px #dadada;
`;

export const ContainerQuestions = styled(Column)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 1rem;
`;

export const ContainerItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  @media ${sizes.md} {
    width: 75%;
  }
`;

export const ContainerTitle = styled.div`
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  @media ${sizes.md} {
  }
`;

export const ContainerInfo = styled.div`
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  display: flex;
  padding: 1.5rem;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: bold;
  flex: 1;
  margin-right: 1rem;
  @media ${sizes.sm} {
    margin-right: 1.5rem;
  }
  text-align: left;
  font-size: 1rem;
`;

export const IconContainer = styled.div`
  margin: auto;
`;

export const ContainerAnswer = styled.div`
  background-color: #eaeaea;
  padding: 1.5rem;
  text-align: left;
  ol,
  ul {
    margin: 0;
    margin-top: 0.5rem;

    li {
      line-height: 2;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
