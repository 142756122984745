import React, { useContext } from 'react'
import ThemeContext from '../../../../context/ThemeContext'
import { FlexRow, LinkResponsive, H3 } from './style'

function TitleSummary() {
  const { primary } = useContext(ThemeContext)
  return (
    <>
      <FlexRow>
        <H3>Resumen de Suscripción</H3>
        <LinkResponsive to={`/`} fontcolor={primary}>
          Editar
        </LinkResponsive>
      </FlexRow>
    </>
  )
}

export default TitleSummary
