import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  backgroundcolor?: string
  color: string
  icon?: string
  sizeicon?: string
  bordercolor?: string
  third?: string
}

export const NavButtonContainer = styled(Link)<Props>`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0rem 0.8rem;
  font-weight: bold;
  line-height: 1.2;
  border-radius: 20px;
  font-size: 12px;
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border: ${(props) =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  &:hover {
    background-color: ${(props) => (props.third ? props.third : props.color)};
    color: ${(props) => props.backgroundcolor};
    border: 1px solid ${(props) => props.backgroundcolor};
  }

  @media ${sizes.sm} {
    padding: 0.7rem 0rem 0.7rem;
  }
`

export const ButtonContainer = styled.button<Props>`
  font-family: 'Muller-Bold';
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0rem 0.8rem;
  font-weight: bold;
  line-height: 1.2;
  border-radius: 20px;
  font-size: 12px;
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border: ${(props) =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  &:hover {
    background-color: ${(props) => props.color};
    color: ${(props) => props.backgroundcolor};
    border: 1px solid ${(props) => props.backgroundcolor};
  }
`

export const Title = styled.span`
  margin: auto;
  font-weight: bold;
`

export const StyledNavIconButton = styled(Link)<Props>`
  border-radius: 50%;
  color: #ffffff;
  padding: auto;
  border: none;
  font-size: 18px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  z-index: 2;

  transition: background-color 0.25s ease-out, opacity 0.25s ease-out;

  background-color: ${(props) => props.color};

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${(props) => props.color};
  }

  ${(props) => props.disabled && 'opacity: 0.4; cursor: not-allowed;'};

  @media ${sizes.md} {
    width: 45px;
    height: 45px;
    font-size: ${(props) => (props.sizeicon ? props.sizeicon : '26px')};
  }
`

export const StyledIconButton = styled.button<Props>`
  border-radius: 50%;
  background-color: ${(props) => props.color};
  color: #ffffff;
  padding: auto;
  border: none;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  z-index: 2;
  width: 30px;
  height: 30px;
`

export const Label = styled.span`
  display: block;
  padding: 0 10px;
`
