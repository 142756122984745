import React, { useContext } from "react";
import ThemeContext from "../../../../context/ThemeContext";
import { FlexRow, LinkResponsive, H3 } from "./style";
import Icon from "../../../Icon";

function TitleSummaryResponsive(iconName: any) {
  const icon = iconName.iconName;

  const { primary } = useContext(ThemeContext);
  return (
    <>
      <FlexRow>
        <FlexRow>
          <Icon color="black" size="20px" name={icon} className="kronos-icon" />
          <H3>Resumen de Suscripción</H3>
        </FlexRow>
        <LinkResponsive to={`/`} fontcolor={primary}>
          Editar
        </LinkResponsive>
      </FlexRow>
    </>
  );
}

export default TitleSummaryResponsive;
