import styled from "styled-components";
import sizes from "../../styles/media";
import { Link } from "react-router-dom";
interface Props {
  backgroundcolor?: string;
  order?: number;
  width?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media ${sizes.md} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const ImageContainer = styled.div`
  flex: auto;
  padding: 1.5rem 0 0 0;
  display: inline-table;
  text-align: center;
  min-height: 80px;
  height: 90px;
  width: auto;

  @media ${sizes.md} {
    width: auto;
    min-height: 80px;
    height: 90px;
    margin: 1rem auto;
    padding: 0;
    display: flex;
  }
`;

export const Img = styled.img`
  max-width: 100%;
  margin: 0 auto;
  max-height: 90px;
`;

export const InstructionsContainer = styled.div`
  width: 100%;
  padding-bottom: 0;
  @media ${sizes.md} {
    flex-direction: row;
    justify-content: center;
    padding-bottom: 0;
  }

  & p {
    margin: 0;
    @media ${sizes.md} {
      margin: 15px 0;
    }
  }
`;

export const Title = styled.h2`
  font-family: "Roboto";
  display: block;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
  color: #000;
  text-align: center;
  font-size: 1.7rem;
  margin: 2rem 0 1rem;
  @media ${sizes.md} {
    margin: 2rem 0 2rem;
    font-size: 2.1rem;
  }
`;

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0.5rem;
  @media ${sizes.md} {
    margin-bottom: 2.2rem;
  }
`;
export const Step = styled.div`
  color: #000;
  text-align: center;
  font-size: 14px;
  padding: 0 0.5rem;
  display: flex;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  margin: 0 1.8rem;
  width: 80%;

  &:first-child {
    margin-top: 0;
  }

  @media ${sizes.md} {
    width: unset;
    display: inline-block;
    flex: 0 0 26%;
    text-align: center;
  }
`;

export const StepMobile = styled.div`
  display: flex;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  padding: 0 0.5rem;
`;

export const Wrapper = styled.div`
  color: #000;
  padding: 0 0.4rem;
  margin-bottom: 15px;
  margin: 0 5px;
  width: 266px;
  height: auto;
  outline: none;

  &:first-child {
    margin-top: 0;
  }

  @media ${sizes.md} {
    width: unset;
    display: inline-block;
    flex: 0 0 26%;
    text-align: center;
  }
`;
export const TextContainer = styled.div`
  font-size: 17px;
  letter-spacing: -0.2px;

  p {
    margin: 1rem 0;
    line-height: 1.43;
  }
  @media ${sizes.md} {
    letter-spacing: normal;
    font-size: 18px;
  }
`;
export const Description = styled.div`
  ${(props: Props) => {
    let styles = `
        background-color: ${
          props.backgroundcolor ? props.backgroundcolor : "#ffffff"
        };
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 0.5rem;
    `;

    return styles;
  }};
`;

export const DescriptionBlue = styled(Description)`
  ${(props: Props) => {
    let styles = `
        background-color: ${
          props.backgroundcolor ? props.backgroundcolor : "#ffffff"
        };
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 1rem;
        
        @media ${sizes.md} {
          flex-direction: column;
          padding: 1rem 1rem 0.5rem;
        }
    `;

    return styles;
  }};
`;

export const TitleSmall = styled(Title)`
  font-weight: normal;
  color: #ffffff;
  line-height: 1.2;
  @media ${sizes.lg} {
    margin-top: 9px;
  }
`;

export const TitleOffer = styled(Title)`
  font-weight: normal;
  line-height: 1.1;
  color: #000000;
  font-size: 20px;
  @media ${sizes.md} {
    line-height: 0.95;
    font-size: 16px;
  }
  @media ${sizes.lg} {
    font-size: 20px;
  }
`;

export const SubTitleOffer = styled(Title)`
  font-weight: normal;
  line-height: 1.1;
  color: #000000;
  font-size: 12px;
  margin-top: 10px;
  @media ${sizes.md} {
    line-height: 0.95;
    font-size: 10px;
  }
  @media ${sizes.lg} {
    font-size: 12px;
  }
`;

export const Price = styled(Title)`
  font-weight: bold;
  color: #f8e946;
  font-size: 24px;
  @media ${sizes.md} {
    font-size: 24px;
  }
`;

export const RegPrice = styled.span`
  display: block;
  color: #ff4d4d;
  text-decoration: line-through;
  margin-top: 15px;
  @media ${sizes.md} {
    margin-top: 5px;
  }
`;

export const OfferPrice = styled.span`
  display: block;
  color: #000;
  font-size: 24px;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PromoBox = styled.div`
  ${(props: Props) => {
    let styles = `
          display: flex;
          flex-direction: column;
          margin-top: 0.5rem;
      `;

    if (props.order) {
      const order = `
          order:${props.order};
      `;

      styles += order;
    }

    if (props.width) {
      const order = `
          width:${props.width}%;
          @media ${sizes.md} {
            width:100%;
          }
      `;

      styles += order;
    }

    return styles;
  }};
`;

export const StyledBanner = styled.img`
  width: 100%;
  display: flex;
  max-width: 100%;
  margin: auto;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${sizes.lg} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${sizes.lg} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
