import { createGlobalStyle } from "styled-components";
import "normalize.css";
import sizes from "../styles/media";
import "normalize.css";
import "react-awesome-slider/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const GlobalStyle = createGlobalStyle`

  html {
      font-family: 'Roboto';
      font-size: 14px;
      /* min-height: 100%;
      height: 100%; */
      body{
          /* min-height: 100%; */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          *, ::after, ::before{
            box-sizing: border-box;
          }
          #root{
            /* min-height: 100vh; */
            display: flex;
          }
        } 
  }
  select:hover{ outline:none; }
  .slick-list{
    padding: 1rem 1rem 2rem 1rem;
  }
  .slick-slide{
    width: 266px;
    margin: 0;
  }
  .slick-track{
    padding: 1rem 0 2rem 0;
  }

  .animate-spin {
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    display: inline-block;
  }

  input[type=search]{
    -webkit-appearance: none;
  }
  
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-o-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-ms-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  input, textarea{
      box-shadow: inset 0px 0px 0px 0px red;
  }

  input, textarea, button {
      font-size: 14px;
      padding: 0;
  }

  h2 {
      font-size: 1.75rem;
  }

  h3 {
      font-size: 18px;
  }
  
  h2, h3 {
      font-family: 'Muller-Bold';
      font-weight: bold;
  }

  a {
      text-decoration: none;
  }

  .kronos-icon
  {
    line-height: 0em;
  }

  .collapsible{

    display: block;
    font-weight: 400;
    text-decoration: none;
    color: black;
    position: relative;
    padding: 10px;

    &:after {
      content: '*';
      position: absolute;
      left: 0px;
      top: 35px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
        &:after {
        transform: rotateZ(180deg);
      }
    }

  }

  .responsive-table-description{
    table{
      table-layout: fixed;
      td{
        padding: 10px 20px 5px 20px;
        word-wrap:break-word;
        &:first-child {
          width: 40% ;
        }
        &:nth-child(2) {
          width: 60% ;
        }
      }
      @media only screen and (max-width: 700px) {
        td{
          padding: 10px 10px 5px 10px !important;
          width: 50% !important;
        }
      }
    }
  }


    
  /* // Animaciones CSS */
  .animated {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }


  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  
.awssld {
  --content-background-color: #eeeeee;
	--slider-height-percentage: 41%;
	--slider-transition-duration: 300ms;
	--organic-arrow-thickness: 2px;
	--organic-arrow-border-radius: 15px;
	--organic-arrow-height: 10px;
	--organic-arrow-color: #000000;
	--control-button-height: 40px;
	--control-button-width: 40px;
	--control-button-opacity: 1;
	--control-button-background: #ffffff;
	--control-bullet-color: #ffffff;
	--control-bullet-active-color: #c59d5f;
	--loader-bar-color: #c59d5f;
  --loader-bar-height: 1px;

  @media ${sizes.sm} {
    --slider-height-percentage: 25%;
	--loader-bar-color: #c59d5f;
  --loader-bar-height: 1px;
  }
  .awssld__next{
    border-radius: 50%;
  }
  .awssld__prev{
    border-radius: 50%;
  }
  .awssld__timer{
    height: 1px;
    background-color: transparent;
  }
  .awssld__content{
    div{
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      a{
        width: 100%;
        flex: 1;
        max-height: 100%;
      }
    }
  }
  
	.awssld__bullets {
    bottom: 5px;
    @media ${sizes.lg} {
      bottom: 10px;
    }

    z-index: 98;
		button {
			border: solid 1px #ffffff;
      width: 10px;
      height: 10px;
			&.awssld__bullets--active {
      width: 7px;
      height: 7px;
				border-color: #c59d5f;
			}
		}
	}
}
`;
