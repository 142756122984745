import { createClient } from "../client";
import { VALIDATE_GT_DOCUMENT_QUERY } from "../views/Billing/query";
const client = createClient();
//validate if identificationNumber id GT member
export async function validateGtMember(value: string) {
  const { data } = await client.query({
    query: VALIDATE_GT_DOCUMENT_QUERY,
    variables: {
      filters: {
        idn: value,
      },
    },
    fetchPolicy: "network-only",
  });
  if (!data) {
    return {
      error: true,
      message: "Este documento no pertenece a Grande Table",
      hide: false,
      value: value,
      fillColor: "#FFF",
    };
  }
  if (data.document.isGt) {
    return {
      error: false,
      message: "Valido",
      hide: false,
      value: value,
      fillColor: "#FFF",
    };
  } else {
    return {
      error: true,
      message: "Este documento no pertenece a Grande Table",
      hide: false,
      value: value,
      fillColor: "#FFF",
    };
  }
}
