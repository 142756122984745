import styled from "styled-components";
import { Link } from "react-router-dom";
import sizes from "../../styles/media";
import { Column } from "../Grid";
import background from "../../assets/banners/seasson-background.jpg";
import backgroundMobile from "../../assets/banners/seasson-background-mobile.jpg";

export const StyledColumn = styled(Column)`
  display: flex;
`;

export const Title = styled.h2`
  font-family: "Roboto";
  display: block;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
  margin: 2rem 0 1.5rem;
  font-size: 1.7rem;
  @media ${sizes.md} {
    font-size: 2.1rem;
    margin: 2rem 0 2rem;
  }

`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  h4 {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.82;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0 auto 1rem;
    @media ${sizes.sm} {
      font-size: 22px;
    }
  }
  p {
    font-family: "Roboto-Light";
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 100%;
    margin: 0 auto;
    @media ${sizes.sm} {
      font-size: 20px;
      width: 50%;
    }
  }
`;
export const Wrapper = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  background-image: url(${backgroundMobile});
  background-size: cover;
  background-position: 0 center;
  background-repeat: no-repeat;
  color: white;
  padding: 0 0 2rem;
  @media ${sizes.md} {
    background-image: url(${background});
  }
`;

export const FlexCol = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* position: relative; */
  label,
  span {
    margin-bottom: 7px;
    width: 30%;
    font-weight: bold;
    text-align: center;
  }
  span {
    margin-right: 10px;
    margin-top: 0px;
  }

  @media ${sizes.sm} {
    width: 90%;
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  padding: 1.5rem;
  border: 2px solid;
  border-color: transparent;
  background-color: white;
  margin: 1rem 0;
  width: 100%;
  flex: 1;
  &:hover {
    border-color: #1428a0;
    box-shadow: 0 4px 8px 0 rgba(117, 120, 123, 0.6);
    cursor: pointer;
  }
`;

export const ImgContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`;

export const ProductContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`;

export const ProductResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  height: 100%;
`;

export const LinkResponsive = styled(Link)`
  color: black;
`;

export const PriceContainer = styled.div`
  display: flex-end;
`;

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  color: #231f20;
  text-transform: uppercase;
`;
