import styled from "styled-components";
import sizes from "../../styles/media";

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const Gif = styled.img`
  opacity: 1;
  position: absolute;
  top: 40%;
  left: 30%;
  width: 10rem;

  @media ${sizes.sm} {
    top: 40%;
    left: 35%;
  }

  @media ${sizes.md} {
    top: 40%;
    left: 43%;
  }

  @media ${sizes.xl} {
    top: 40%;
    left: 45%;
  }
`;
