import styled from "styled-components";
import { Link } from "react-router-dom";
import sizes from "../../styles/media";

export const ProductContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media ${sizes.md} {
      flex-direction: row;
    }
  }
  &.active {
    border-color: #c59d5f;
  }
  &.inactive {
    border-color: #9d9d9d;
    &:hover {
      border-color: #ccc;
    }
  }

  @media ${sizes.md} {
    padding: 1.5rem;
    margin: 1rem 0;
    flex-direction: column;
  }
`;
export const Reference = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 0.5rem;
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  border-radius: 30px;

  img {
    width: 100%;
    border-radius: 30px;
  }
  margin-bottom: 2rem;
  @media ${sizes.md} {
    margin-bottom: 0;
    margin-left: inherit;
    width: 50%;
    img {
      width: 100%;
    }
  }
`;
export const Detail = styled.div`
  width: 100%;
  color: white;
  @media ${sizes.md} {
    width: 50%;
    padding-left: 2rem;
  }
`;
export const ImgContainerMobile = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  @media ${sizes.sm} {
    display: none;
  }
`;

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`;

export const ProductInfoContainer = styled.div`
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;
  @media ${sizes.md} {
  width: 90%;
  }
`;

export const FrequencyInfoContainer = styled.div`
  display: flex;
  margin-bottom: 0.7rem;
`;

export const FrequencyInfoContainerMobile = styled.div`
  display: flex;
  margin-bottom: 0.7rem;
  padding: 0;
  @media ${sizes.md} {
    display: none;
  }
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  padding: 0;
  @media ${sizes.md} {
    margin-top: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
  width: 90%;
  max-width: 320px;
  margin: 0 auto 1rem;
  @media ${sizes.md} {
    width: 50%;
    max-width: 300px;
    margin: 1rem 0;
  }
`;
export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      padding: 0 15px;
      font-weight: bold;
      height: 31px;
      `;
  }};
`;

interface Props {
  backgroundcolor?: string;
  fontcolor?: string;
}

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      color: ${props.fontcolor};
      border: solid 1px ${props.backgroundcolor};
      outline: none;
      cursor: pointer;
      width: 31px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 4px;
    `;
  }};
`;

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
`;

export const Title = styled.span`
  font-weight: normal;
  font-size: 16px;
  margin-right: 0.5rem;
  display: inline-block;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 300px;
  margin-bottom: 1rem;
  @media ${sizes.lg} {
    margin-bottom: 1rem;
    width: 60%;
    justify-content: flex-start;
    align-items: flex-start;
  }
  i {
    margin-right: 4px;
  }
  .quantity-title {
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #c59d5f;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  @media ${sizes.md} {
    align-items: flex-start;
  }
  @media ${sizes.lg} {
    margin-bottom: 1rem;
    flex-direction: column;
  }
`;

export const Price = styled.p`
  font-family: "Muller-Bold";
  font-size: 3rem;
  margin: 0;
  color: #c59d5f;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  @media ${sizes.md} {
    text-align: center;
    font-size: 3.1rem;
  }
`;

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`;

export const CustomTitle = styled.p`
  font-family: "Roboto";
  font-size: 1.4rem;
  font-weight: bold;
  word-break: break-word;
  letter-spacing: 2.14px;
  padding: 0 1rem;
  margin: 0 0 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #c59d5f;
  text-align: center;
  @media ${sizes.md} {
    font-size: 1.6rem;
    text-align: left;
    padding: 0;
  }
`;

export const ProductContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`;

export const ProductResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  height: 100%;
`;

export const LinkResponsive = styled(Link)`
  color: black;
`;

export const PriceContainer = styled.div`
  display: flex-end;
`;

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1.5rem;
`;
export const PriceInfo = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
  @media only screen and (max-width: 320px) {
    font-size: 0.9rem;
  }
  @media ${sizes.sm} {
    text-align: left;
    font-size: 16px;
  }
`;

export const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 28px;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerTitle = styled.div`
  border-bottom: solid 2px #c59d5f;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
  padding: 1rem 0 0.3rem;
  @media ${sizes.md} {
  }
`;
export const ContainerAnswer = styled.div`
  background-color: transparent;
  padding: 1rem 0;
  text-align: left;
  font-size: 16px;
  font-family: "Roboto-Light";
  ol,
  ul {
    margin: 0;
    margin-top: 0.5rem;

    li {
      line-height: 2;
    }
  }

  a {
    color: #c59d5f;
    &:visited {
      color: #c59d5f;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 320px;
  margin: 0.5rem 0 0.5rem;
  .quantity-title {
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #c59d5f;
  }
  @media ${sizes.lg} {
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    margin: 0;
  }
`;
