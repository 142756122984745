import React from "react";
import ResumeViewHandler from "../views/ResumeViewHandler";

type SwitchProps = {
  Component: React.ComponentType;
  FallbackComponent: React.ComponentType;
};

type ResumeFallBackProps = {
  Component: React.ComponentType;
  FallbackComponent: React.ComponentType;
};

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props;
  return (
    <ResumeViewHandler>
      {(hasResume: boolean) => {
        if (!hasResume) {
          return <FallbackComponent {...rest} />;
        } else {
          return <Component {...rest} />;
        }
      }}
    </ResumeViewHandler>
  );
};

const ResumeFallback = (
  Component: React.ComponentType,
  FallbackComponent: React.ComponentType
) => {
  return (props: ResumeFallBackProps) => (
    <Switch
      {...props}
      Component={Component}
      FallbackComponent={FallbackComponent}
    />
  );
};

export default ResumeFallback;
