import styled from "styled-components";
import sizes from "../../../../styles/media";

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  margin: 0;
  box-shadow: 0 2px 4px 0 rgba(178, 178, 178, 0.5);

  a,
  span {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  a,
  button {
    cursor: default;
  }
  span {
    font-size: 12px;
    font-weight: bold;
  }

  @media ${sizes.md} {
    padding: 1rem 2rem;
    span {
      font-size: 14px;
    }

    background-color: transparent;
    box-shadow: inherit;
  }
  @media ${sizes.xl} {
    padding: 1rem 7rem;
    span {
      font-size: 14px;
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
`;

export const Step = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

export const Line = styled.hr`
  position: absolute;
  border: 1px solid #e0e0e0;
  width: 70%;
  top: 13%;
  left: 14%;

  @media ${sizes.sm} {
    width: 70%;
  }
  @media ${sizes.md} {
    top: 25%;
  }

  @media ${sizes.lg} {
    width: 70%;
    top: 20%;
    left: 15%;
  }

  @media ${sizes.xl} {
    width: 70%;
    left: 15%;
  }
`;
