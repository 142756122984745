export type TypeStep = {
  pathname: string;
  name: string;
  icon: string;
  sizeicon: string;
  order: number;
};

export const Steps: TypeStep[] = [
  {
    pathname: "/shipping",
    name: "Contacto <br>de entrega",
    icon: "location-1",
    sizeicon: "26px",
    order: 1,
  },
  {
    pathname: "/billing",
    name: "Datos de <br>facturación",
    icon: "doc-text",
    sizeicon: "26px",
    order: 2,
  },
  {
    pathname: "/payment",
    name: "Confirmación <br>Susripción",
    icon: "money",
    sizeicon: "26px",
    order: 3,
  },
];
