import styled from "styled-components";
import { Link } from "react-router-dom";
import sizes from "../../styles/media";

export const PaymentContainer = styled.div`
  padding: 27px 21px;
  background-color: white;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  display: flex;
  flex-direction: column;

  @media ${sizes.md} {
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    grid-auto-rows: minmax(min-content, max-content);
  }
`;

export const Card = styled.div`
  margin-bottom: 1rem;
  min-width: 50%;
  overflow-wrap: break-word;
  @media ${sizes.md} {
    margin-bottom: 0;
    padding: 14px 20px;
    border: solid 1px #d9d9d9;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleCard = styled.h4`
  margin-top: 0;
`;

interface LinkResponsiveProps {
  fontcolor?: string;
}

export const LinkResponsive = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
  ${(props: LinkResponsiveProps) => {
    return `
      color: ${props.fontcolor};
    `;
  }}
  color: #c59d5f;
  &:hover {
    color: #c59d5f;
  }
  &:visited {
    color: #c59d5f;
  }
`;

export const BodyCard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleBody = styled.span`
  font-weight: bold;
  margin-right: 5px;
  line-height: 1.5;
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
  @media ${sizes.md} {
    margin-top: 0;
  }
  label {
    color: #353535;
    font-size: 14px;
  }
  u {
    color: #c59d5f;
    &:visited {
      color: #c59d5f;
    }
  }
  &.centered {
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @media ${sizes.md} {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
`;

export const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c59d5f;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-right: 0.6rem;
  cursor: pointer;
  display: flex;
  background-color: white;
`;

export const ContainerTransaction = styled.div`
  display: flex;
  justify-content: center;
  color: #75787b;
  font-size: 12px;
  label {
    margin: 2%;
  }
`;

export const ContainerFranchise = styled.div`
  img {
    width: 100%;
  }
`;
export const ContainerLink = styled.a`
  max-width: 80px;

  img {
    width: 100%;
  }
`;

export const ContainerTerms = styled.a`
  color: black;
  text-decoration: underline;
  margin: 0.5rem;
`;
