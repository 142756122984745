import styled from "styled-components";
import sizes from "../../styles/media";

export const ContainerResume = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  padding: 3rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media ${sizes.md} {
    padding: 5rem;
  }
`;

export const ContainerTitle = styled.div`
  margin-bottom: 0;
  h2 {
    color: #000000;
    margin-bottom: 0;
    font-size: 20px;
  }

  @media ${sizes.md} {
    h2 {
      font-size: 1.75rem;
    }
  }
`;

type InfoContainerProps = {
  align?: string;
};
export const DeliverContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  @media ${sizes.sm} {
    width: 60%;
  }

  @media ${sizes.xl} {
    width: 50%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props: InfoContainerProps) => props.align};
  justify-content: center;

  strong {
    font-size: 13px;
  }

  p {
    max-width: 180px;
    font-size: 13px;
    text-align: left;
    margin: 0 0 0 0.6rem;
    @media ${sizes.md} {
      max-width: 280px;
    }
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const CallUsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5.1px;
`;

export const LittleSpan = styled.span`
  font-size: 9.8px;
  /* @media */
`;

export const NumberSpan = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const IconContainer = styled.div`
  margin-right: 10px;
  @media ${sizes.sm} {
    margin-right: 5px;
  }
`;

export const VerticalLine = styled.div`
  background-color: black;
  border: solid 1px black;
  width: 1px;
  height: 31px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  @media ${sizes.md} {
    width: 30%;
  }
`;

export const StyledLink = styled.a`
  width: 100%;
  border-radius: 100px;
  background-color: #000000;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  padding: 0.7rem 3rem;
  border: 1px solid;
  border-color: #000000;
  transition: 0.2s ease-in;
  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
`;
