import styled from "styled-components";

export const LoaderContainer = styled.div`
  margin: auto;
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 2rem;
`;

export const Gif = styled.img`
  width: 5rem;
  max-width: 100%;
  height: auto;
  margin: auto;
`;
