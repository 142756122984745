import styled from "styled-components";

interface Props {
  backgroundcolor?: string;
}

export const ContainerForm = styled.div`
  ${(props: Props) => {
    return `
      width: 100%;
      background-color: ${props.backgroundcolor};
      box-shadow: 0 2px 4px 0 #d9d9d9;
      padding: 31px 32px 0px 33px;
      margin-bottom: 2rem;
      h2 {
        margin-bottom: 5px;
      }
      &:focus {
        border: solid 1px #2684FF;
      }
    `;
  }}
`;

export const Hr = styled.hr`
  border: 1px solid #d9d9d9;
  margin-top: 0;
  margin-bottom: 28px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  /* position: relative; */
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 0px;
  }
`;

export const Span = styled.span`
  margin-bottom: 7px;
`;

export const ContainerElements = styled.div`
  margin-bottom: 1rem;
`;

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Element = styled.div`
  width: 80%;
`;

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  margin-top: 3px;
  line-height: 2;
`;

export const StyledAction = styled.span`
  margin: 0 !important;
  font-weight: bold;
  color: #008900;
`;

export const StyledNewChoice = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: #9cd878;
    ${StyledAction} {
      color: #fff;
    }
  }
`;
export const StyledNewData = styled.span`
  margin: 0 !important;
  position: relative;
  top: 2px;
`;

type PropsSpan = {
  empty?: boolean;
};
export const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 0px;
  }

  .css-dpec0i-option {
    background-color: ${(props: PropsSpan) => {
      return props.empty === true ? "#9cd878 !important" : "initial";
    }};
    -webkit-tap-highlight-color: ${(props: PropsSpan) =>
      props.empty === true ? "unset" : "rgba(0, 0, 0, 0)"};
  }
`;
