import React from "react";
import Collapsible from "react-collapsible";
import Layout from "../../components/Layout";
import { Row, Column } from "../../components/Grid";

import {
  ContainerTitle,
  Hr,
  ContainerQuestions,
  ContainerItem,
  ContainerAnswer,
  Title,
} from "./style";

import { questionItems } from "./data";
import Icon from "../../components/Icon";

function FrequentQuestion() {
  return (
    <Layout backgroundColor={"white"}>
      <Row>
        <Column md={12}>
          <h3>Preguntas frecuentes</h3>
          <Hr />
        </Column>

        <ContainerQuestions md={12}>
          {questionItems.map((item, key) => (
            <ContainerItem key={key}>
              <Collapsible
                trigger={
                  <ContainerTitle>
                    <Title>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      />
                    </Title>
                    <>
                      <Icon
                        color="#9b9b9b"
                        size="20px"
                        name="down-open"
                        className="kronos-icon"
                      />
                    </>
                  </ContainerTitle>
                }
                triggerWhenOpen={
                  <ContainerTitle>
                    <Title>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      />
                    </Title>
                    <>
                      <Icon
                        color="#9b9b9b"
                        size="20px"
                        name="up-open"
                        className="kronos-icon"
                      />
                    </>
                  </ContainerTitle>
                }
              >
                <ContainerAnswer>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.answer,
                    }}
                  />
                </ContainerAnswer>
              </Collapsible>
            </ContainerItem>
          ))}
        </ContainerQuestions>
      </Row>
    </Layout>
  );
}

export default FrequentQuestion;
