import styled from "styled-components";
import { Column, Row } from "../../components/Grid";
import Icon from "../../components/Icon";
import sizes from "../../styles/media";

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
`;

export const ItemSlider = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImgDesktop = styled.img`
  display: none;
  width: 100%;
  @media ${sizes.sm} {
    display: flex;
  }
`;
export const ImgMobile = styled.img`
  display: flex;
  max-width: 100%;
  @media ${sizes.sm} {
    display: none;
  }
`;

export const BannersContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.md} {
    display: flex;
    flex-direction: row;
  }
`;

type ContainerProps = {
  backgroundColor?: string;
  color?: string;
};

export const MainBanner = styled.div`
  background-image: none;
  height: 100%;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  display: flex;
  align-items: center;
  .banner {
    width: 100%;
    text-align: center;
    color: white;
    position: absolute;
    img {
      width: 60%;
      max-width: 250px;
      margin: 0 auto;
      @media ${sizes.md} {
        max-width: 250px;
        width: 250px;
      }
      @media ${sizes.lg} {
        max-width: 290px;
        width: 290px;
      }
    }
    h3 {
      font-family: "Roboto";
      width: 85%;
      max-width: 260px;
      border-top: 2px solid #c59d5f;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin: 1.5rem auto 0;
      padding: 1.5rem 0.5rem 0;
      @media ${sizes.md} {
        padding: 1.5rem 5rem 0;
        width: 80%;
        max-width: 800px;
        font-size: 1.2rem;
      }
      @media ${sizes.lg} {
        font-size: 1.8rem;
        width: 60%;
      }
    }
  }
`;

export const CategoryContainer = styled.div`
  z-index: 2;
  background-color: #f7f7f7;
  padding-bottom: 1rem;
`;

export const CategoryResponsive = styled.div`
  @media ${sizes.lg} {
    display: none;
  }
`;

export const CategoryDesktop = styled.div`
  display: none;
  @media ${sizes.lg} {
    display: block;
  }
`;

export const PromoBannerContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
`;

export const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0.5rem 0;

  @media ${sizes.md} {
    margin: 0;
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto 2rem;
  @media ${sizes.md} {
    width: 80%;
  }
  img {
    width: 100%;
  }
  p {
    font-family: "Roboto-Light";
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #333333;
    @media ${sizes.md} {
      font-size: 20px;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: #333333;
    margin: 0 auto 1rem;
    @media ${sizes.md} {
      font-size: 32px;
    }
  }
`;

export const StyledAnimateBanner = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyleContentText = styled.div`
  display: none;
  margin: 5px 3em;
  && h1 {
    font-size: 40px;
    margin: 0;
    margin-top: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
  }

  p {
    margin: 0;
    font-family: "Muller-Bold";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
  }

  @media ${sizes.lg} {
    display: initial;
  }
`;

export const CategoryTitle = styled.h1`
  font-family: "Roboto";
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${(props: ContainerProps) => props.color};
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  margin-top: 35px;
  letter-spacing: 1.38px;

  @media ${sizes.sm} {
    margin-top: 0;
    justify-content: center;
    letter-spacing: 1.85px;
    font-size: 24px;
  }
`;
export const LabelCategory = styled.label`
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  margin: 0;
  padding: 15px 0;
  color: ${(props: ContainerProps) => props.color};
  line-height: 1.43;
  font-size: 1rem;
  margin-bottom: 20px;
  cursor: pointer;

  @media ${sizes.sm} {
    padding: 25px 30px;
    justify-content: flex-start;
  }
`;
export const Title = styled.h2`
  font-family: "SensationsandQualities";
  display: block;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
  color: #000;
  text-align: center;
  font-size: 2.3rem;
  margin: 2rem 0 1rem;
  @media ${sizes.sm} {
    font-size: 4rem;
    margin: 0 0 2.5rem;
  }
`;

export const CenteredRow = styled(Row)`
  justify-content: center;
`;

export const CategoryImages = styled.img`
  width: 26px;
  margin-right: 15px;
`;

export const CategoryButtonContainer = styled.div`
  box-shadow: 0 2px 8px 0 rgba(78, 78, 78, 0.3);
  background: ${(props: ContainerProps) => props.backgroundColor};
`;
interface CouponProps {
  hasCoupon?: boolean;
}

export const BellContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 1.3rem;
  right: 0.8rem;

  display: ${(props: CouponProps) => (props.hasCoupon ? "flex" : "none")};
  align-items: center;

  cursor: ${(props: CouponProps) => (props.hasCoupon ? "pointer" : "")};
  i {
    &:before {
      margin: 0;
    }
    animation: mymove 0.8s infinite;
  }
  &:hover {
    i {
      color: #c59d5f;
    }
  }

  @keyframes mymove {
    from {
      left: -2px;
    }
    to {
      left: 2px;
    }
  }

  @media ${sizes.md} {
    top: 2rem;
    right: 8%;
  }
`;

export const StyledIcon = styled(Icon)`
  position: relative;
  right: -0.8rem;
  @media ${sizes.md} {
    right: 0;
  }
`;

export const Oval = styled.span`
  font-family: Arial;
  color: #ffffff;
  background: red;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  width: 18px;
  height: 18px;
  padding-top: 3px;
`;
