import React, { useContext, useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import gql from "graphql-tag";
import { graphql, withApollo, compose } from "react-apollo";

import Checkout from "../../components/Checkout";
import * as Grid from "../../components/Grid";
import { NavButton, Button } from "../../components/Buttons";
import ProductSummary from "../../components/Checkout/components/ProductSummary";
import TitleSummary from "../../components/Checkout/components/TitleSummary";
import DateDelivery from "../../components/Checkout/components/DateDelivery";
import Icon from "../../components/Icon";
//import franchiseImg from "../../assets/p2p/franchiseImage.png";
import { ContainerElements } from "../../components/Global/style";
import Loader from "../../components/loader";

import { useIsMobile, useIsIpad } from "../../helpers/resize";

import ThemeContext from "../../context/ThemeContext";
import CartContext from "../../context/CartContext";
import LoaderContext from "../../context/LoaderContext";
import Customer from "../../model/Customer";
import Address from "../../model/Address";

import {
  PaymentContainer,
  Card,
  HeaderCard,
  LinkResponsive,
  BodyCard,
  TitleCard,
  TitleBody,
  TermsContainer,
  Checkbox,
} from "./style";
import TitleSummaryResponsive from "../../components/Checkout/components/TitleSummaryResponsive";

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $paymentResponse: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      document: $document
      paymentResponse: $paymentResponse
      docUpdated: $docUpdated
    ) {
      token
    }
  }
`;

const GENERATE_ORDER_MUTATION = gql`
  mutation generateOrder($input: CreateOrderInput!) {
    generateOrder(input: $input) {
      id
      status
      provider_payment_code
    }
  }
`;

function Payment({ history, mutate, client }: any) {
  const {
    onSetLoaded,
    loaded,
    updateItems,
    //onDelAllItems,
    forceShowNotification,
  } = useContext(CartContext);
  const { setLoading } = useContext(LoaderContext);
  const initialMql = window.matchMedia(`(max-width: 767px)`);
  const isMobile = useIsMobile(initialMql.matches);

  const initialMqlIpad = window.matchMedia(`(max-width: 1024px)`);
  const isIpad = useIsIpad(initialMqlIpad.matches);

  const { primary, secondary } = useContext(ThemeContext);
  const [termsState, setTerms] = useState(false);

  const {
    cityName,
    city,
    province,
    provinceName,
    zoneName,
    street1,
    street2,
    number,
    zone,
    reference,
    firstName,
    lastName,
    phone,
    email,
  }: Address = JSON.parse(window.localStorage.getItem("address") || "{}");

  const {
    identificationType,
    identificationTypeName,
    identificationNumber,
    firstName: firstNameCustomer,
    lastName: lastNameCustomer,
    businessName: businessNameCustomer,
    mobile: mobileCustomer,
    address: addressCustomer,
    email: emailCustomer,
    coupon: coupon,
  }: Customer = JSON.parse(window.localStorage.getItem("customer") || "{}");

  const itemsCart = JSON.parse(window.localStorage.getItem("items") || "{}");

  const generateOrder = async () => {
    if (termsState == true) {
      setLoading && setLoading({ loading: true });

      const items: any[] = [];
      itemsCart.forEach((item: any) => {
        for (let i = 0; i < item.quantity; i++) {
          items.push({ variationId: item.id });
        }
      });

      const order = {
        address: {
          state: province,
          city: city,
          zone: zone,
          reference: reference,
          street1: street1,
          street2: street2,
          number: number,
          email: email,
          customerName: `${firstName} ${lastName}`,
          phone: phone,
        },
        customer: {
          firstName: firstNameCustomer,
          lastName: lastNameCustomer,
          identificationNumber: identificationNumber,
          identificationType: identificationType,
          mobile: mobileCustomer,
          email: emailCustomer,
          address: addressCustomer,
          businessName: businessNameCustomer,
          coupon: coupon,
        },
        lineItems: items,
      };
      const orderResult = await mutate({
        mutation: GENERATE_ORDER_MUTATION,
        variables: {
          input: order,
        },
      });
      // setOrderId(order_id.data.generateOrder.id);
      setLoading && setLoading({ loading: false });
      if (
        orderResult.data &&
        orderResult.data.generateOrder &&
        orderResult.data.generateOrder.status &&
        orderResult.data.generateOrder.status === "created"
      ) {
        history.push("/resume");
      } else {
        forceShowNotification &&
          forceShowNotification({
            type: "alert",
            message: "Algo salió mal, inténtalo más tarde.",
          });
      }
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: "alert",
          message: "Para avanzar, debe aceptar los términos y condiciones",
        });
    }
  };

  useEffect(() => {
    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok);
      });
  });

  return loaded ? (
    <Checkout>
      <Grid.Row>
        {isMobile && (
          <Grid.Column>
            <ContainerElements>
              <Collapsible
                trigger={<TitleSummaryResponsive iconName="down-open" />}
                triggerWhenOpen={<TitleSummaryResponsive iconName="up-open" />}
              >
                <ProductSummary />
              </Collapsible>
            </ContainerElements>
          </Grid.Column>
        )}
        {isMobile && (
          <Grid.Column>
            <ContainerElements>
              <DateDelivery />
            </ContainerElements>
          </Grid.Column>
        )}
        <Grid.Column md={isMobile || isIpad ? 12 : 9}>
          <div className="animated fadeInLeft">
            <h3>Resumen del Pedido</h3>
            <PaymentContainer>
              <Card>
                <HeaderCard>
                  <TitleCard>Persona de Contacto</TitleCard>
                  <LinkResponsive to="/shipping">Editar</LinkResponsive>
                </HeaderCard>

                <BodyCard>
                  <div>
                    <TitleBody>Nombre:</TitleBody>
                    <span>{firstName}</span>
                  </div>
                  <div>
                    <TitleBody>Apellido:</TitleBody>
                    <span>{lastName}</span>
                  </div>
                  <div>
                    <TitleBody>Teléfono de Contacto:</TitleBody>
                    <span>{phone}</span>
                  </div>
                  <div>
                    <TitleBody>Correo Electrónico:</TitleBody>
                    <span>{email}</span>
                  </div>
                </BodyCard>

                <h4>Dirección de envío</h4>
                <BodyCard>
                  <div>
                    <TitleBody>Provincia:</TitleBody>
                    <span>{provinceName}</span>
                  </div>
                  <div>
                    <TitleBody>Ciudad:</TitleBody>
                    <span>{cityName}</span>
                  </div>
                  <div>
                    <TitleBody>Calle Principal:</TitleBody>
                    <span>{street1}</span>
                  </div>
                  <div>
                    <TitleBody>Calle Secundaria:</TitleBody>
                    <span>{street2}</span>
                  </div>
                  <div>
                    <TitleBody>Numeración:</TitleBody>
                    <span>{number}</span>
                  </div>
                  <div>
                    <TitleBody>Sector o Referencia:</TitleBody>
                    <span>{zoneName}</span>
                  </div>
                  <div>
                    <TitleBody>Detalle de Vivienda:</TitleBody>
                    <span>{reference}</span>
                  </div>
                </BodyCard>
              </Card>
              <Card>
                <HeaderCard>
                  <TitleCard>Datos del Cliente</TitleCard>
                  <LinkResponsive to="/billing" fontcolor="#1428a0">
                    Editar
                  </LinkResponsive>
                </HeaderCard>
                <BodyCard>
                  <div>
                    <TitleBody>Tipo de Identificación:</TitleBody>
                    <span>{identificationTypeName}</span>
                  </div>
                  <div>
                    <TitleBody>Identificación:</TitleBody>
                    <span>{identificationNumber}</span>
                  </div>

                  {identificationType == "RUC" ? (
                    <div>
                      <TitleBody>Razón Social:</TitleBody>
                      <span>{businessNameCustomer}</span>
                    </div>
                  ) : (
                    <>
                      <div>
                        <TitleBody>Nombre:</TitleBody>
                        <span>{firstNameCustomer}</span>
                      </div>
                      <div>
                        <TitleBody>Apellido:</TitleBody>
                        <span>{lastNameCustomer}</span>
                      </div>
                    </>
                  )}
                  <div>
                    <TitleBody>Teléfono Celular:</TitleBody>
                    <span>{mobileCustomer}</span>
                  </div>
                  <div>
                    <TitleBody>Dirección:</TitleBody>
                    <span>{addressCustomer}</span>
                  </div>
                  <div>
                    <TitleBody>Correo Electrónico:</TitleBody>
                    <span>{emailCustomer}</span>
                  </div>
                </BodyCard>
              </Card>
            </PaymentContainer>
          </div>
        </Grid.Column>

        {!isMobile && (
          <Grid.Column md={isIpad ? 6 : 3}>
            <TitleSummary />
            <ContainerElements>
              <ProductSummary />
            </ContainerElements>
            {!isIpad && (
              <>
                <ContainerElements>
                  <DateDelivery />
                </ContainerElements>
                <TermsContainer onClick={() => setTerms(!termsState)} className="centered">
                  <Checkbox onClick={() => setTerms(!termsState)}>
                    {termsState == true ? (
                      <Icon
                        color="black"
                        size="11px"
                        name="ok"
                        className="kronos-icon"
                      />
                    ) : (
                      <div />
                    )}
                  </Checkbox>
                  <label onClick={() => setTerms(!termsState)}>
                    Acepto{" "}
                    <a
                      target="_blank"
                      href="https://grande-table.s3.amazonaws.com/tyc_gt_farms_2020.pdf"
                    >
                      <u>Términos y condiciones</u>
                    </a>
                  </label>
                </TermsContainer>
                <ContainerElements>
                  <Button
                    backgroundColor={"#c59d5f"}
                    color={secondary}
                    onClick={() => {
                      generateOrder();
                    }}
                    id={"MKTPL_PAY_NEXT"}
                  >
                    SUSCRIBIRME
                  </Button>
                </ContainerElements>
                {/* <ContainerFranchise>
                  <img src={franchiseImg} />
                </ContainerFranchise> */}

                <ContainerElements>
                  <NavButton
                    to="/billing"
                    backgroundColor="#f7f7f7"
                    color={primary}
                    bordercolor={primary}
                    third={"#000000"}
                    onClick={() => {
                      onSetLoaded && onSetLoaded(false);
                    }}
                  >
                    Atrás
                  </NavButton>
                </ContainerElements>
              </>
            )}
          </Grid.Column>
        )}
        {!isMobile && isIpad && (
          <Grid.Column md={6} style={{ margin: "3.5rem 0 0" }}>
            <ContainerElements>
              <DateDelivery />
            </ContainerElements>
            <TermsContainer onClick={() => setTerms(!termsState)} className="centered">
              <Checkbox>
                {termsState == true ? (
                  <Icon
                    color="black"
                    size="11px"
                    name="ok"
                    className="kronos-icon"
                  />
                ) : (
                  <div />
                )}
              </Checkbox>
              <label>
                Acepto{" "}
                <a
                  target="_blank"
                  href="https://grande-table.s3.amazonaws.com/tyc_gt_farms_2020.pdf"
                >
                  <u>Términos y condiciones</u>
                </a>
              </label>
            </TermsContainer>
            <ContainerElements>
              <Button
                backgroundColor={"#c59d5f"}
                color={secondary}
                onClick={() => {
                  generateOrder();
                }}
                id={"MKTPL_PAY_NEXT"}
              >
                SUSCRIBIRME
              </Button>
            </ContainerElements>
            {/* <ContainerFranchise>
              <img src={franchiseImg} />
            </ContainerFranchise> */}
            <ContainerElements>
              <NavButton
                to="/billing"
                backgroundColor="#f7f7f7"
                color={primary}
                bordercolor={primary}
                third={"#000000"}
                onClick={() => {
                  onSetLoaded && onSetLoaded(false);
                }}
              >
                Atrás
              </NavButton>
            </ContainerElements>
          </Grid.Column>
        )}

        {isMobile && (
          <Grid.Column>
            <TermsContainer onClick={() => setTerms(!termsState)} className="centered">
              <Checkbox>
                {termsState == true ? (
                  <Icon
                    color="black"
                    size="11px"
                    name="ok"
                    className="kronos-icon"
                  />
                ) : (
                  <div />
                )}
              </Checkbox>
              <label>
                Acepto{" "}
                <a
                  target="_blank"
                  href="https://grande-table.s3.amazonaws.com/tyc_gt_farms_2020.pdf"
                >
                  <u>Términos y condiciones</u>
                </a>
              </label>
            </TermsContainer>
            <ContainerElements>
              <Button
                backgroundColor={"#c59d5f"}
                color={secondary}
                onClick={() => {
                  generateOrder();
                }}
                id={"MKTPL_PAY_NEXT"}
              >
                SUSCRIBIRME
              </Button>
            </ContainerElements>
            {/* <ContainerFranchise>
              <img src={franchiseImg} />
            </ContainerFranchise> */}
            <ContainerElements>
              <NavButton
                to="/billing"
                backgroundColor="#f7f7f7"
                color={primary}
                bordercolor={primary}
                third={"#000000"}
                onClick={() => {
                  onSetLoaded && onSetLoaded(false);
                }}
              >
                Atrás
              </NavButton>
            </ContainerElements>
          </Grid.Column>
        )}
      </Grid.Row>
    </Checkout>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
    </Checkout>
  );
}

const AddTrackWithMutation = compose(
  graphql(GENERATE_ORDER_MUTATION),
  graphql(ADD_TRACKING_MUTATION)
)(withApollo(Payment));

export default AddTrackWithMutation;
