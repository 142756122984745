import styled from "styled-components";
import sizes from "../../../../styles/media";

export const DateDeliveyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 4px;
  background-color: #f9f4ee;
  border: solid 1px #caa66f;
  padding: 1rem 2rem;
  margin: 1rem 0;
  text-align: center;
  align-items: center;
  font-size: 12px;
  color: #caa66f;

  @media ${sizes.xs} {
    padding: 1rem 1.5rem;
  }

  @media ${sizes.md} {
    padding: 1rem 0.5rem;
  }

  @media ${sizes.xl} {
    max-width: 258px;
    padding: 1rem 1.5rem 1rem 1rem;
  }
`;

export const DateIcon = styled.div`
  img {
    max-width: 50px;
  }
`;

export const DateInfo = styled.div`
  line-height: 1.2;
  display: flex;
  margin-left: 10px;
  ul {
    margin: 0;
  }
`;

export const DateText = styled.span`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  letter-spacing: normal;
  color: #caa66f;
  line-height: 1.2;
  text-align: left;
  padding-right: 0.2rem;
  p{
    margin: 0;
  }
`;
