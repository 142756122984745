import styled from 'styled-components'

type ItemListProps = {
  active: boolean
}

export const BreadcrumbContainer = styled.div``

export const StyledList = styled.ol`
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  display: flex;
  width: 100%;
`

export const ItemList = styled.li<ItemListProps>`
  float: left;
  color: #231f20;
  color: ${(props) => (props.active ? '#75787b' : '#231f20')};
  font-family: ${(props) => (props.active ? 'Muller-Regular' : 'Muller-Bold')};
  & + &::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #636c72;
    content: '/';
  }

  &:last-child,
  &:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: ${(props) => (props.active ? '#75787b' : '#231f20')};
    &:visited {
      color: currentColor;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`
